import { useState, useMemo } from 'react'
import { DataType } from './types'
import { hardCodedTableData } from './hardCodedTableData'

const LOCAL_STORAGE_KEY = 'forebyggande_insatser_filter_favorites'

const InitialFilterValues = {
  Id: [],
  'Risk för ökat omsorgsbehov': [],
  Resursbehov: [],
  Ålder: [],
  Kön: [],
  'Frånvaro på sjukhus': [],
  Fallolycka: [],
  'Kognitiv svikt': [],
  Dagverksamhet: [],
  Korttidsboende: [],
  'Social aktivitet': [],
  'KVÅ - Balansträning': [],
  'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': [],
  'KVÅ - Kompressionsbehandling': [],
  'KVÅ - Nutritionsbehandling': [],
  'KVÅ - Omläggning av sår UNS': [],
  'Hög larmfrekvens, flagga': [],
  'Avböjda besök, flagga': [],
  'Trend omsorgsbehov 3mån': [],
  'Trend omsorgsbehov 6mån': [],
  'Utförd insats av fysioterapeut': [],
  'Utförd insats av sjuksköterska': [],
  'Utförd insats av arbetsterapeut': [],
  'Utförd insats av omvårdnadspersonal': [],
  'Nivå hemsjukvård': [],
  'Bedömt funktionstillstånd, Känsla av trygghet': [],
  'Avsett funktionstillstånd, Känsla av trygghet': [],
  'Bedömt funktionstillstånd, Att förflytta sig själv': [],
  'Avsett funktionstillstånd, Att förflytta sig själv': [],
  'Bedömt funktionstillstånd, Att genomföra daglig rutin': [],
  'Avsett funktionstillstånd, Att genomföra daglig rutin': [],
}

const getFromLocalStorage = () => {
  const localData = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (localData) {
    return JSON.parse(localData) as { [K in keyof DataType]: DataType[K][] }
  }
  return InitialFilterValues
}

const setToLocalStorage = (data: { [K in keyof DataType]: DataType[K][] }) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
}

export const useForebyggandeInsatserTable = () => {
  const [filteredInfo, setFilteredInfo] = useState<{ [K in keyof DataType]: DataType[K][] }>(getFromLocalStorage())

  const resetFilters = () => setFilteredInfo(InitialFilterValues)

  const saveFavoriteFilters = () => setToLocalStorage(filteredInfo)

  const filteredTableData = useMemo(() => {
    return hardCodedTableData
      .filter((record) => {
        return Object.entries(filteredInfo).every(([key, value]) => {
          if (value.length === 0) return true
          return (value as string[]).includes(record[key as keyof DataType])
        })
      })
      .map((record) => ({
        ...record,
        'Risk för ökat omsorgsbehov': Number(record['Risk för ökat omsorgsbehov']).toFixed(1),
        Resursbehov: Number(record.Resursbehov).toFixed(1),
      }))
  }, [hardCodedTableData, filteredInfo])

  const options = useMemo(() => {
    const ids = new Set<string>()
    const ages = new Set<string>()
    const sex = new Set<string>()
    const inläggningPåSjukhus = new Set<string>()
    const fallolycka = new Set<string>()
    const kognitivSvikt = new Set<string>()
    const dagverksamhet = new Set<string>()
    const korttidsboende = new Set<string>()
    const socialAktivitet = new Set<string>()
    const balansträning = new Set<string>()
    const träning = new Set<string>()
    const kompressionsbehandling = new Set<string>()
    const nutritionsbehandling = new Set<string>()
    const omläggningAvSår = new Set<string>()
    const högLarmfrekvens = new Set<string>()
    const avböjdaBesök = new Set<string>()
    const trendOmsorgsbehov3mån = new Set<string>()
    const trendOmsorgsbehov6mån = new Set<string>()
    const utfördInsatsAvFysioterapeut = new Set<string>()
    const utfördInsatsAvSjuksköterska = new Set<string>()
    const utfördInsatsAvArbetsterapeut = new Set<string>()
    const utfördInsatsAvOmvårdnadspersonal = new Set<string>()
    const riskFörÖkatOmsorgsbehov = new Set<string>()
    const resursbehov = new Set<string>()
    const nivåHemsjukvård = new Set<string>()
    const bedömtFunktionstillståndKänslaAvTrygghet = new Set<string>()
    const avsettFunktionstillståndKänslaAvTrygghet = new Set<string>()
    const bedömtFunktionstillståndAttFörflyttaSigSjälv = new Set<string>()
    const avsettFunktionstillståndAttFörflyttaSigSjälv = new Set<string>()
    const bedömtFunktionstillståndAttGenomföraDagligRutin = new Set<string>()
    const avsettFunktionstillståndAttGenomföraDagligRutin = new Set<string>()

    hardCodedTableData.forEach((record) => {
      ids.add(record.Id)
      ages.add(record.Ålder)
      sex.add(record.Kön)
      inläggningPåSjukhus.add(record['Frånvaro på sjukhus'])
      fallolycka.add(record.Fallolycka)
      kognitivSvikt.add(record['Kognitiv svikt'])
      dagverksamhet.add(record.Dagverksamhet)
      korttidsboende.add(record.Korttidsboende)
      socialAktivitet.add(record['Social aktivitet'])
      balansträning.add(record['KVÅ - Balansträning'])
      träning.add(record['KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet'])
      kompressionsbehandling.add(record['KVÅ - Kompressionsbehandling'])
      nutritionsbehandling.add(record['KVÅ - Nutritionsbehandling'])
      omläggningAvSår.add(record['KVÅ - Omläggning av sår UNS'])
      högLarmfrekvens.add(record['Hög larmfrekvens, flagga'])
      avböjdaBesök.add(record['Avböjda besök, flagga'])
      trendOmsorgsbehov3mån.add(record['Trend omsorgsbehov 3mån'])
      trendOmsorgsbehov6mån.add(record['Trend omsorgsbehov 6mån'])
      utfördInsatsAvFysioterapeut.add(record['Utförd insats av fysioterapeut'])
      utfördInsatsAvSjuksköterska.add(record['Utförd insats av sjuksköterska'])
      utfördInsatsAvArbetsterapeut.add(record['Utförd insats av arbetsterapeut'])
      utfördInsatsAvOmvårdnadspersonal.add(record['Utförd insats av omvårdnadspersonal'])
      riskFörÖkatOmsorgsbehov.add(record['Risk för ökat omsorgsbehov'])
      resursbehov.add(record.Resursbehov)
      nivåHemsjukvård.add(record['Nivå hemsjukvård'])
      bedömtFunktionstillståndKänslaAvTrygghet.add(record['Bedömt funktionstillstånd, Känsla av trygghet'])
      avsettFunktionstillståndKänslaAvTrygghet.add(record['Avsett funktionstillstånd, Känsla av trygghet'])
      bedömtFunktionstillståndAttFörflyttaSigSjälv.add(record['Bedömt funktionstillstånd, Att förflytta sig själv'])
      avsettFunktionstillståndAttFörflyttaSigSjälv.add(record['Avsett funktionstillstånd, Att förflytta sig själv'])
      bedömtFunktionstillståndAttGenomföraDagligRutin.add(record['Bedömt funktionstillstånd, Att genomföra daglig rutin'])
      avsettFunktionstillståndAttGenomföraDagligRutin.add(record['Avsett funktionstillstånd, Att genomföra daglig rutin'])
    })
    return {
      ids,
      ages,
      sex,
      inläggningPåSjukhus,
      fallolycka,
      kognitivSvikt,
      dagverksamhet,
      korttidsboende,
      socialAktivitet,
      balansträning,
      träning,
      kompressionsbehandling,
      nutritionsbehandling,
      omläggningAvSår,
      högLarmfrekvens,
      avböjdaBesök,
      trendOmsorgsbehov3mån,
      trendOmsorgsbehov6mån,
      utfördInsatsAvFysioterapeut,
      utfördInsatsAvSjuksköterska,
      utfördInsatsAvArbetsterapeut,
      utfördInsatsAvOmvårdnadspersonal,
      riskFörÖkatOmsorgsbehov,
      resursbehov,
      nivåHemsjukvård,
      bedömtFunktionstillståndKänslaAvTrygghet,
      avsettFunktionstillståndKänslaAvTrygghet,
      bedömtFunktionstillståndAttFörflyttaSigSjälv,
      avsettFunktionstillståndAttFörflyttaSigSjälv,
      bedömtFunktionstillståndAttGenomföraDagligRutin,
      avsettFunktionstillståndAttGenomföraDagligRutin,
    }
  }, [hardCodedTableData])

  const activeFilters = useMemo(() => {
    return Object.entries(filteredInfo).reduce(
      (acc, [key, value]) => {
        if (value.length > 0) {
          acc.push(key as keyof DataType)
        }
        return acc
      },
      [] as Array<keyof DataType>
    )
  }, [filteredInfo])

  return {
    filteredInfo,
    setFilteredInfo,
    resetFilters,
    saveFavoriteFilters,
    activeFilters,
    filteredTableData,
    options,
  }
}
