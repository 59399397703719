// Antd
import { Button, Checkbox, Divider, Form, FormProps, InputNumber, Select, Tooltip, Typography } from 'antd'
import { FC, Fragment, useState } from 'react'
import { KorttidFormValues, KorttidInputTypes, KorttidLevels, KorttidLevelsExtended } from './types'
import { tooltipTexts } from './utility/tooltipTexts'

const { Option } = Select
const { Text } = Typography

type Props = {
  onFinish: (values: KorttidFormValues) => void
  onFinishFailed: FormProps<KorttidFormValues>['onFinishFailed']
}

const KorttidInput: FC<Props> = ({ onFinish, onFinishFailed }) => {
  const [showAdditionalHoursInput, setShowAdditionalHoursInput] = useState(false)

  return (
    <div className="flex flex-col gap-8">
      <Form<KorttidFormValues> name={'korttid-input'} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div className="grid grid-cols-2 items-end gap-9">
          <Typography.Title level={5}>Behovsområde</Typography.Title>
          <Typography.Title level={5}>Nivå</Typography.Title>
        </div>
        <Divider style={{ width: '100%', marginTop: 12, marginBottom: 0 }} />
        {Object.values(KorttidInputTypes).map((item, index) => (
          <Fragment key={`${item}-${index}`}>
            <div className="grid grid-cols-2  gap-4" key={index}>
              <div className="pt-1">
                <Typography.Text>{item}</Typography.Text>
              </div>
              <Form.Item<KorttidFormValues> name={item} rules={[{ required: true, message: 'Vänligen välj ett alternativ' }]}>
                <Select bordered={false} placeholder="Välj nivå" allowClear>
                  {Object.values([KorttidInputTypes['Daglig verksamhet'], KorttidInputTypes.Psykomotorisk].includes(item as any) ? KorttidLevelsExtended : KorttidLevels).map((level) => (
                    <Option key={`${item}-${level}`} value={level}>
                      <Tooltip title={tooltipTexts[item][level]} placement="right">
                        <div style={{ width: '100%' }}>{level}</div>
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Divider style={{ width: '100%', marginBlock: 1 }} />
          </Fragment>
        ))}
        <div className="mt-8 flex flex-col gap-8">
          <div className="flex flex-col  gap-2">
            <Checkbox value={showAdditionalHoursInput} onChange={(value) => setShowAdditionalHoursInput(value.target.checked)}>
              Korttidstillsyn (LSS) eller Korttidsvistelse
            </Checkbox>
            {showAdditionalHoursInput && (
              <div className="flex flex-wrap gap-4">
                <Form.Item<KorttidFormValues> name={'vardagsDygnPerMonth'} rules={[{ required: true, message: 'Skriv en siffra mellan 0 och 20' }]}>
                  <InputNumber type="number" min={0} max={20} style={{ width: '250px' }} placeholder="Antal vardagsdygn per månad" />
                </Form.Item>
                <Form.Item<KorttidFormValues> name={'helgdagsDygnPerMonth'} rules={[{ required: true, message: 'Skriv en siffra mellan 0 och 8' }]}>
                  <InputNumber type="number" min={0} max={8} style={{ width: '250px' }} placeholder="Antal helgdygn per månad" />
                </Form.Item>
              </div>
            )}
            <Typography.Text className={showAdditionalHoursInput ? '' : 'mt-4'} italic>
              Justering fler/färre timmar
            </Typography.Text>
            <Form.Item<KorttidFormValues> name={'extraHours'}>
              <InputNumber type="number" min={-168} max={168} style={{ width: '250px' }} placeholder="Extratimmar per vecka" />
            </Form.Item>
          </div>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit" className="w-[150px]">
              Beräkna
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default KorttidInput
