import { DataType } from './types'

export const hardCodedTableData: DataType[] = [
  {
    Id: 'Individ13849',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13229.05138',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13850',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25475.20287',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13851',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5032.60884',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13852',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10108.87211',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13853',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13854',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26464.62868',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13855',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3819.118506',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13856',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17577.78308',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13857',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5622.143018',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13858',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28392.87916',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13859',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17577.78308',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13860',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '23272.85177',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13861',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8558.973616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13862',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21572.88838',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13863',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16281.98219',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13864',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2767.464',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13865',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9704.002705',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13866',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9176.21499',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13867',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13868',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '7086.180258',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13869',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5808.344474',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13870',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10260.59074',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13871',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16487.27388',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13872',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13873',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18691.71314',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13874',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7175.047928',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13875',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13876',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10278.69339',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13877',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10068.62483',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13878',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10194.89529',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13879',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '32286.84699',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13880',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7740.448286',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13881',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13882',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17577.78308',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13883',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7367.739342',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13884',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17305.2906',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13885',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '25029.04898',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13886',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20233.17704',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13887',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8472.94059',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13888',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9176.21499',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13889',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3799.9584',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13890',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10068.62483',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13891',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14466.7256',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13892',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16486.17426',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13893',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3376.30608',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13894',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5326.5401',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13895',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '13138.99782',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13896',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16652.14657',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13897',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14184.66443',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13898',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5107.826813',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13899',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4311.9405',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13900',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26318.92382',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13901',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13902',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13903',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ13904',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12697.26995',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13905',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4170.750901',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13906',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15946.35319',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13907',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8974.725286',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13908',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3537.58032',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13909',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11121.57253',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13910',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20233.17704',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13911',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13912',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8565.577826',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13913',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14008.97806',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13914',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11857.9718',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13915',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4203.802526',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13916',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10278.69339',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13917',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13918',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5243.967563',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13919',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13920',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3166.632',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13921',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4245.096384',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13922',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17463.68362',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13923',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13924',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13925',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12203.17326',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13926',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5994.405304',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13927',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6680.94485',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13928',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6266.047346',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13929',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9176.21499',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13930',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6439.00314',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13931',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13932',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4731.28749',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13933',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13934',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5032.60884',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13935',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7584.473674',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13936',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4102.377935',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13937',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3593.28375',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13938',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13739.3949',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13939',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13940',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12082.3498',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13941',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13942',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2595.6',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ13943',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11332.01629',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13944',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8252.971173',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13945',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5107.826813',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13946',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21305.69402',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13947',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16434.15174',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13948',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '23272.85177',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13949',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10450.00495',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13950',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13951',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8472.94059',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13952',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12107.22805',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13953',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3166.632',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13954',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6477.873131',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13955',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16487.27388',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13956',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '33860.33617',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13957',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16072.72201',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13958',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9369.10033',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13959',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20261.51459',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13960',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25860.63923',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13961',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13962',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12356.21305',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13963',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14827.45567',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13964',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '34649.58968',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13965',
    'Risk för ökat omsorgsbehov': '3.7',
    Resursbehov: '75692.97563',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13966',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '30652.52529',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13967',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '39039.13334',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13968',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9801.042703',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13969',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11761.25124',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13970',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7313.174471',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13971',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9033.964423',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13972',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7954.100578',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13973',
    'Risk för ökat omsorgsbehov': '2.9',
    Resursbehov: '59122.36039',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ13974',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '22918.87155',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13975',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6439.00314',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13976',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17754.74501',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13977',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8230.817379',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13978',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13514.15892',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13979',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '7086.180258',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13980',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13981',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5994.405304',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13982',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5380.164315',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13983',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13984',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3166.632',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13985',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5483.229154',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13986',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4659.324578',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13987',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13988',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13989',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3445.739775',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13990',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13991',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6883.685185',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13992',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8397.477771',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13993',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3992.185407',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ13994',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13995',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13996',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3593.28375',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ13997',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '39170.20282',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13998',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15234.73746',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ13999',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14000',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21820.79757',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14001',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12851.01729',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14002',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '35340.70117',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14003',
    'Risk för ökat omsorgsbehov': '2.9',
    Resursbehov: '57870.04969',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14004',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3445.739775',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14005',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7219.784272',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14006',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15234.73746',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14007',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6579.874985',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14008',
    'Risk för ökat omsorgsbehov': '2.2',
    Resursbehov: '43820.67373',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14009',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26224.59842',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14010',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13829.16162',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14011',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28392.87916',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14012',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25876.34038',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14013',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '37318.63722',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14014',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11332.01629',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14015',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18382.89465',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14016',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9176.21499',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14017',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17489.47635',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14018',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9696.643685',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14019',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5152.128588',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14020',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5642.364906',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14021',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10709.18107',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14022',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9116.651097',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14023',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10533.62073',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14024',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10327.6286',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14025',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9639.113018',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14026',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '34761.34541',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14027',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36853.9633',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14028',
    'Risk för ökat omsorgsbehov': '3.1',
    Resursbehov: '62716.19743',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14029',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23912.11241',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14030',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4931.546634',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14031',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14032',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13346.77721',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14033',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8908.58617',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14034',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9091.791141',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14035',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '15135.84699',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14036',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14037',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9608.696565',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14038',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8334.039925',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14039',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5067.667463',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14040',
    'Risk för ökat omsorgsbehov': '4.1',
    Resursbehov: '82226.50259',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14041',
    'Risk för ökat omsorgsbehov': '4.2',
    Resursbehov: '84645.33221',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14042',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '30272.87368',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14043',
    'Risk för ökat omsorgsbehov': '3.0',
    Resursbehov: '61480.698',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14044',
    'Risk för ökat omsorgsbehov': '2.5',
    Resursbehov: '50141.19341',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14045',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13174.36231',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14046',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38171.3235',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14047',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3709.983168',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14048',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '27524.40405',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14049',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '34000.89633',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14050',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18097.92125',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14051',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14195.88635',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14052',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2899.656',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14053',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10533.62073',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14054',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4170.750901',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14055',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14056',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5808.344474',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14057',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14058',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14059',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3831.207975',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14060',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4694.088612',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14061',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13568.31849',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14062',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16379.16056',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14063',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4701.970755',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14064',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10002.60103',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14065',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14066',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '13138.99782',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14067',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11857.9718',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14068',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11908.7489',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14069',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19287.26642',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14070',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18089.49591',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14071',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4582.942207',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14072',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3771.816221',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14073',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14074',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2595.6',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14075',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '32511.76659',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14076',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '7086.180258',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14077',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2899.656',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14078',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16486.17426',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14079',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3709.983168',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14080',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8775.809365',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14081',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7460.13804',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14082',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8086.668921',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14083',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13345.88704',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14084',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2899.656',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14085',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14086',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5736.404321',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14087',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7367.739342',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14088',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5291.9649',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14089',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6848.646336',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14090',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21337.16115',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14091',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14092',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9639.113018',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14093',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5415.315768',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14094',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5773.883278',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14095',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20423.63723',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14096',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8252.971173',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14097',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36614.73988',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14098',
    'Risk för ökat omsorgsbehov': '3.5',
    Resursbehov: '71882.63267',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14099',
    'Risk för ökat omsorgsbehov': '2.8',
    Resursbehov: '56896.89724',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14100',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15425.79605',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14101',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28169.64536',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14102',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17921.87507',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14103',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '17019.69769',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14104',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21506.25009',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14105',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26073.3084',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14106',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5808.344474',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14107',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3992.185407',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14108',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5591.189493',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14109',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '27572.96506',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14110',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23680.35115',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14111',
    'Risk för ökat omsorgsbehov': '2.3',
    Resursbehov: '46846.96',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14112',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '27918.75716',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14113',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14288.63557',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14114',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21713.83544',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14115',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14008.97806',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14116',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26621.37304',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14117',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '15135.84699',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14118',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11869.62287',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14119',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8157.51099',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14120',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9243.320993',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14121',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5608.888475',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14122',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12406.43196',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14123',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6729.465822',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14124',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3831.207975',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14125',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7302.119812',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14126',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14127',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14128',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11328.50034',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14129',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5291.9649',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14130',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9170.64708',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14131',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18697.31178',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14132',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14133',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14613.48201',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14134',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11829.9053',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14135',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3911.74051',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14136',
    'Risk för ökat omsorgsbehov': '2.9',
    Resursbehov: '58570.6143',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14137',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25860.63923',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14138',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '33974.01843',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14139',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8397.477771',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14140',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16072.72201',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14141',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19600.09214',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14142',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17628.07384',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14143',
    'Risk för ökat omsorgsbehov': '3.6',
    Resursbehov: '73776.8376',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14144',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '31554.22041',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14145',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16403.35152',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14146',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12797.89101',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14147',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14148',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14149',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14150',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5380.164315',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14151',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6689.539568',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14152',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12448.08515',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14153',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14195.88635',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14154',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14155',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14156',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5192.95726',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14157',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9285.656015',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14158',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '13012.18169',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14159',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14160',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11907.19631',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14161',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5447.636867',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14162',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4659.324578',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14163',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12851.01729',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14164',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4731.28749',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14165',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5679.229454',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14166',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14167',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4102.377935',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14168',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4134.88773',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14169',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3847.613616',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14170',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15946.35319',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14171',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14172',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20233.17704',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14173',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5447.636867',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14174',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16760.94383',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14175',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3445.739775',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14176',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6537.164241',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14177',
    'Risk för ökat omsorgsbehov': '2.6',
    Resursbehov: '52530.88687',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14178',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9116.651097',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14179',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14180',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4732.691212',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14181',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6883.685185',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14182',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14183',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15581.09315',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14184',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11869.62287',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14185',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5004.901081',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14186',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4311.9405',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14187',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6375.763613',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14188',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14189',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14190',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10709.18107',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14191',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11869.62287',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14192',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7423.821809',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14193',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10244.92288',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14194',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5107.826813',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14195',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10108.87211',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14196',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5846.293454',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14197',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11418.27442',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14198',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3847.613616',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14199',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38171.3235',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14200',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14201',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8460.132819',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14202',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8558.973616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14203',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14008.97806',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14204',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4203.802526',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14205',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18316.35598',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14206',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38528.06835',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14207',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11004.7765',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14208',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7576.492617',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14209',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7423.821809',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14210',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11004.7765',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14211',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4170.750901',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14212',
    'Risk för ökat omsorgsbehov': '2.6',
    Resursbehov: '52025.21891',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14213',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4731.28749',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14214',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36853.9633',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14215',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14216',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16760.94383',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14217',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18689.27623',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14218',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8558.973616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14219',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14827.45567',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14220',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8424.060095',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14221',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9101.368409',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14222',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7576.492617',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14223',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14008.97806',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14224',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26392.46298',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14225',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3847.613616',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14226',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7302.119812',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14227',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6537.164241',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14228',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8527.176175',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14229',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14230',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14231',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14232',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5591.189493',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14233',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5773.883278',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14234',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5192.95726',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14235',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14236',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5808.344474',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14237',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8503.416309',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14238',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3819.118506',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14239',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5608.888475',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14240',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14241',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4659.324578',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14242',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4134.88773',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14243',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5192.95726',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14244',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14184.66443',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14245',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14229.56616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14246',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6537.164241',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14247',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14248',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3831.207975',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14249',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11086.47815',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14250',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3847.613616',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14251',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16860.98087',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14252',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23825.56021',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14253',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13174.36231',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14254',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14648.15257',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14255',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4701.970755',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14256',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5380.164315',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14257',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3878.1045',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14258',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12674.90775',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14259',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4102.377935',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14260',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4870.466197',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14261',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14262',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9033.964423',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14263',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9116.651097',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14264',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4298.334068',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14265',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11829.9053',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14266',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5707.20528',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14267',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9608.696565',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14268',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8778.017274',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14269',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6309.698462',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14270',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14271',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12293.90746',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14272',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11566.93562',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14273',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26073.3084',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14274',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '29252.33778',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14275',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2899.656',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14276',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '29834.0187',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14277',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8503.416309',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14278',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17463.68362',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14279',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4582.942207',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14280',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14281',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2767.464',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14282',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3799.9584',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14283',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3581.945057',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14284',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9639.113018',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14285',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14286',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3847.613616',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14287',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11978.26394',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14288',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10199.71415',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14289',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24762.44386',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14290',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6746.571622',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14291',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12970.34827',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14292',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9903.565407',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14293',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15809.23477',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14294',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10327.6286',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14295',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11482.7689',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14296',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19854.6335',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14297',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12908.89111',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14298',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11761.25124',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14299',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6729.465822',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14300',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14301',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3581.945057',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14302',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14303',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16652.14657',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14304',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9923.957419',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14305',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24684.47599',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14306',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20570.39666',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14307',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24994.0436',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14308',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5415.315768',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14309',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14310',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14311',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4409.97075',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14312',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10664.90918',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14313',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10769.67034',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14314',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13303.77377',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14315',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20570.39666',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14316',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14467.82912',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14317',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4102.377935',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14318',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19287.26642',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14319',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '7050.110682',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14320',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26621.37304',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14321',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '27168.84126',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14322',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25991.03345',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14323',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15613.42704',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14324',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3992.185407',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14325',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13873.76498',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14326',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10769.67034',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14327',
    'Risk för ökat omsorgsbehov': '4.3',
    Resursbehov: '86259.1592',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14328',
    'Risk för ökat omsorgsbehov': '2.2',
    Resursbehov: '44488.70968',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14329',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16694.04051',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14330',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6945.033271',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14331',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18863.94408',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14332',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11142.78722',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14333',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12056.52426',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14334',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18382.89465',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14335',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14336',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7954.100578',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14337',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20112.99358',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14338',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8355.34853',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14339',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13433.97875',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14340',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15564.41792',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14341',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10167.52871',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14342',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19296.24963',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14343',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6628.417149',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14344',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16230.604',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14345',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12540.00594',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14346',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12851.01729',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14347',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14613.48201',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14348',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8157.51099',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14349',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10403.15493',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14350',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21820.79757',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14351',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14352',
    'Risk för ökat omsorgsbehov': '2.2',
    Resursbehov: '44224.75596',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14353',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9020.308604',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14354',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4732.691212',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14355',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3942.739575',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14356',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15613.42704',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14357',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14827.45567',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14358',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3799.9584',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14359',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7900.91231',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14360',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11086.47815',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14361',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14362',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6537.164241',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14363',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16760.94383',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14364',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24684.47599',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14365',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14366',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24075.02367',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14367',
    'Risk för ökat omsorgsbehov': '4.3',
    Resursbehov: '87696.81185',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14368',
    'Risk för ökat omsorgsbehov': '2.5',
    Resursbehov: '51172.34647',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14369',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36135.41285',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14370',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '13011.1892',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14371',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14372',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16885.88345',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14373',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14613.48201',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14374',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3376.30608',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14375',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5994.405304',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14376',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '31163.40071',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14377',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '22356.36212',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14378',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15325.66539',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14379',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7855.58383',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14380',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3091.65264',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14381',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14382',
    'Risk för ökat omsorgsbehov': '2.0',
    Resursbehov: '39883.94208',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14383',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11798.40009',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14384',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '35783.90832',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14385',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8472.94059',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14386',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '29538.63247',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14387',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5415.315768',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14388',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2595.6',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14389',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5260.56741',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14390',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8334.039925',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14391',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7644.577762',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14392',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10068.62483',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14393',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11194.98229',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14394',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5326.5401',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14395',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14396',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14397',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4298.334068',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14398',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2767.464',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14399',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14400',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3537.58032',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14401',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8075.358987',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14402',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4582.942207',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14403',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8753.558473',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14404',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6883.685185',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14405',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4409.97075',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14406',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4311.9405',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14407',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8033.64156',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14408',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5591.189493',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14409',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3581.945057',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14410',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6967.017863',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14411',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4731.28749',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14412',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7975.340374',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14413',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9923.957419',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14414',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15613.42704',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14415',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12082.3498',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14416',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '31554.22041',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14417',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13514.15892',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14418',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4674.07373',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14419',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3831.207975',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14420',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25876.34038',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14421',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6456.197178',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14422',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '19101.92252',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14423',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12003.12124',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14424',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14425',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19287.26642',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14426',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '22263.94063',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14427',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '30112.84404',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14428',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6729.465822',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14429',
    'Risk för ökat omsorgsbehov': '2.3',
    Resursbehov: '45913.83284',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14430',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18863.94408',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14431',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11978.26394',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14432',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '30112.84404',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14433',
    'Risk för ökat omsorgsbehov': '4.5',
    Resursbehov: '91425.02134',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14434',
    'Risk för ökat omsorgsbehov': '3.5',
    Resursbehov: '70992.31916',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14435',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10321.36204',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14436',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16281.98219',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14437',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14528.67366',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14438',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24454.11369',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14439',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5483.229154',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14440',
    'Risk för ökat omsorgsbehov': '2.4',
    Resursbehov: '48829.83087',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14441',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '35687.85209',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14442',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17305.2906',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14443',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '27093.13883',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14444',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14445',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9923.957419',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14446',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14648.15257',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14447',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4424.776729',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14448',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '15074.57993',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14449',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14450',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9903.565407',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14451',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10840.75731',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14452',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4659.324578',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14453',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3831.207975',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14454',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3878.1045',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14455',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14456',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4102.377935',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14457',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14458',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11159.65625',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14459',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7697.832124',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14460',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3992.185407',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14461',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13433.97875',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14462',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14463',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8885.866646',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14464',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14465',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14466',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14467',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8252.971173',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14468',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6182.554305',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14469',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3593.28375',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14470',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13346.77721',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14471',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2595.6',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14472',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8885.866646',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14473',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3819.118506',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14474',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5032.60884',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14475',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17305.2906',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14476',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '31569.13527',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14477',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14827.45567',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14478',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '31757.55441',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14479',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5291.9649',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14480',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10499.75574',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14481',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10167.52871',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14482',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11907.19631',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14483',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '29346.89372',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14484',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8157.51099',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14485',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19926.76035',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14486',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9696.643685',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14487',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7516.923836',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14488',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14467.82912',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14489',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14490',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9334.117929',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14491',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2595.6',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14492',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18697.31178',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14493',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9639.113018',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14494',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4245.096384',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14495',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7900.91231',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14496',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5004.901081',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14497',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14498',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '15135.84699',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14499',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5004.901081',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14500',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9243.320993',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14501',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5004.901081',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14502',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9243.320993',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14503',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10244.92288',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14504',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11142.78722',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14505',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13701.92931',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14506',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18324.88699',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 3',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14507',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6546.319859',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14508',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14288.63557',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14509',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6546.319859',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14510',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5994.405304',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14511',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13739.3949',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14512',
    'Risk för ökat omsorgsbehov': '1.5',
    Resursbehov: '29538.63247',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14513',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21506.25009',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14514',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16676.74071',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14515',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14516',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19538.24358',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14517',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10108.87211',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14518',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15816.59806',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14519',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6859.014482',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14520',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10499.75574',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14521',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17536.17841',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14522',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4223.056219',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14523',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4170.750901',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14524',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '18697.31178',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14525',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9696.643685',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14526',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11418.27442',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14527',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7650.916335',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14528',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5192.95726',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14529',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 3',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14530',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6309.698462',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14531',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5679.229454',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14532',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3114.72',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14533',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8472.94059',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14534',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5309.732074',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14535',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10199.71415',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14536',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9704.002705',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14537',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16594.99394',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14538',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7367.739342',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14539',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4424.776729',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14540',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7584.473674',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14541',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14542',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8503.416309',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14543',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13433.97875',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14544',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11332.01629',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14545',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11665.36644',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14546',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5365.83595',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14547',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13739.3949',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14548',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12517.92071',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14549',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10466.62351',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14550',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8558.973616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14551',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8360.421435',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14552',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3911.74051',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14553',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10260.59074',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14554',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9062.561554',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14555',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3320.9568',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 3',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14556',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14557',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5032.60884',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14558',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3581.945057',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14559',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4870.466197',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14560',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10278.69339',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14561',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5736.404321',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14562',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12203.17326',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14563',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4582.942207',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14564',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10581.05829',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14565',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5447.636867',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14566',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14528.67366',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14567',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8472.94059',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14568',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13433.97875',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14569',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14421.0755',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14570',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14553.06968',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14571',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11194.98229',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14572',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16230.604',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14573',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9704.002705',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14574',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15613.42704',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14575',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17305.2906',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14576',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11086.47815',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14577',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3418.648279',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14578',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12517.92071',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14579',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38997.3528',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14580',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11482.7689',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14581',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5291.9649',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14582',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11857.9718',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14583',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5844.559436',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14584',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5152.128588',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14585',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5243.967563',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14586',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16230.604',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14587',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10499.75574',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14588',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5415.315768',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14589',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5243.967563',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14590',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8334.039925',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14591',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23957.46149',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14592',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5622.143018',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14593',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4256.522344',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14594',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7428.329143',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14595',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10976.71107',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14596',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15490.66934',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14597',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8499.761793',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14598',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6859.014482',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14599',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14466.7256',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14600',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4438.783416',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14601',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12259.0646',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14602',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13303.77377',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14603',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14229.56616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14604',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14827.45567',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14605',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17305.2906',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14606',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14607',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14608',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9568.974086',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14609',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5808.344474',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14610',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3911.74051',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14611',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26318.92382',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14612',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2899.656',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14613',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14614',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13911.70043',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14615',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36252.21781',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14616',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11907.19631',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14617',
    'Risk för ökat omsorgsbehov': '4.2',
    Resursbehov: '85747.37933',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14618',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8775.809365',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14619',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4582.942207',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14620',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17489.47635',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14621',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11004.7765',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14622',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17489.47635',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14623',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6039.130608',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14624',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4701.970755',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14625',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11091.98519',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14626',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8027.447482',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14627',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5846.293454',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14628',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5260.56741',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14629',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14630',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14288.63557',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14631',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12487.48972',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14632',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5067.667463',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14633',
    'Risk för ökat omsorgsbehov': '2.0',
    Resursbehov: '40144.52493',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14634',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6970.013368',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14635',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '34424.67511',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14636',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15463.38745',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14637',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9704.002705',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14638',
    'Risk för ökat omsorgsbehov': '2.0',
    Resursbehov: '39883.94208',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14639',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19982.57589',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14640',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5326.5401',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14641',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3593.28375',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14642',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12356.21305',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14643',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5415.315768',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14644',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11761.25124',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14645',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12697.26995',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14646',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20113.1326',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14647',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7679.590434',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14648',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20113.1326',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14649',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23957.46149',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14650',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14651',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7193.286364',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14652',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26553.48068',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14653',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5152.128588',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14654',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9288.537944',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14655',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4526.179465',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14656',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8086.668921',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14657',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8252.971173',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14658',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2767.464',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14659',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7175.047928',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14660',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8355.34853',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14661',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6646.116978',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14662',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9608.696565',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14663',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12056.52426',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14664',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19600.09214',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14665',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20176.66152',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14666',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8188.083704',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14667',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3992.185407',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14668',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21775.95962',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14669',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12854.83005',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14670',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9369.10033',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14671',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4245.096384',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14672',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '27572.96506',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14673',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5773.883278',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14674',
    'Risk för ökat omsorgsbehov': '2.8',
    Resursbehov: '57662.64701',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14675',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11482.7689',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14676',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8775.809365',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14677',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9173.493314',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14678',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7313.174471',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14679',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7679.590434',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 3',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14680',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5679.229454',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14681',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4732.691212',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14682',
    'Risk för ökat omsorgsbehov': '0.1',
    Resursbehov: '2767.464',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14683',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3537.58032',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14684',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14685',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5661.308509',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14686',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14687',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4438.783416',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14688',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3206.34468',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14689',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14690',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14466.7256',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14691',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3479.5872',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14692',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12908.89111',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14693',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '5044.563031',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14694',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26318.92382',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14695',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4409.97075',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14696',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9443.346909',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14697',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14698',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6746.571622',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14699',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13345.88704',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Ja',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14700',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12203.17326',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14701',
    'Risk för ökat omsorgsbehov': '2.0',
    Resursbehov: '39883.94208',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14702',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5243.967563',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14703',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14704',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25543.77108',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14705',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '37865.06449',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14706',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6498.378922',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14707',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26637.53606',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14708',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7644.577762',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14709',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8550.492287',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14710',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13701.92931',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14711',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '7050.110682',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14712',
    'Risk för ökat omsorgsbehov': '3.4',
    Resursbehov: '69195.17641',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14713',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7778.431608',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14714',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14715',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '37865.06449',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14716',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12487.48972',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14717',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14528.67366',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14718',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '39170.20282',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14719',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '36614.73988',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14720',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8606.357166',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14721',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11065.70032',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14722',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10295.03952',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14723',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3911.74051',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14724',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14725',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4597.44957',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14726',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8360.421435',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14727',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5380.164315',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14728',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7855.58383',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14729',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9173.493314',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14730',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10167.52871',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14731',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '9033.964423',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14732',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4245.096384',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14733',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5067.667463',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14734',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8503.416309',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14735',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12908.89111',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14736',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24684.47599',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14737',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '23825.56021',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14738',
    'Risk för ökat omsorgsbehov': '2.1',
    Resursbehov: '42408.8414',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14739',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21495.57248',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14740',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19600.09214',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14741',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15490.66934',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14742',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20113.1326',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14743',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10068.62483',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14744',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4694.088612',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14745',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6216.7817',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14746',
    'Risk för ökat omsorgsbehov': '2.4',
    Resursbehov: '47860.7305',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14747',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8086.668921',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14748',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8503.416309',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14749',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38399.14754',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14750',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12851.01729',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14751',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3709.983168',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14752',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21377.22548',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14753',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14613.48201',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14754',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14755',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10709.18107',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14756',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7900.91231',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14757',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4298.334068',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14758',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12771.38783',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14759',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6231.548712',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14760',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7975.340374',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14761',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21305.69402',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14762',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12293.90746',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14763',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3231.75375',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14764',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6689.539568',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14765',
    'Risk för ökat omsorgsbehov': '1.8',
    Resursbehov: '37073.92473',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14766',
    'Risk för ökat omsorgsbehov': '3.1',
    Resursbehov: '62430.26269',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14767',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11194.98229',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14768',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20423.63723',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14769',
    'Risk för ökat omsorgsbehov': '3.0',
    Resursbehov: '60664.24519',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14770',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26224.59842',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14771',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '32602.60952',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14772',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16760.94383',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14773',
    'Risk för ökat omsorgsbehov': '2.8',
    Resursbehov: '55872.85145',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14774',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3581.945057',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14775',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14776',
    'Risk för ökat omsorgsbehov': '2.8',
    Resursbehov: '56896.89724',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14777',
    'Risk för ökat omsorgsbehov': '2.7',
    Resursbehov: '54131.67826',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14778',
    'Risk för ökat omsorgsbehov': '2.3',
    Resursbehov: '47264.46476',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14779',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28169.64536',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14780',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14781',
    'Risk för ökat omsorgsbehov': '1.9',
    Resursbehov: '38650.02944',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14782',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10260.59074',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14783',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5642.364906',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14784',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26073.3084',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14785',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '21197.24527',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14786',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '25029.04898',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14787',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '25991.03345',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14788',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20570.39666',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14789',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16607.79885',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14790',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12674.90775',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14791',
    'Risk för ökat omsorgsbehov': '2.2',
    Resursbehov: '43820.67373',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14792',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5994.405304',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14793',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4659.324578',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14794',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9923.957419',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14795',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14373.91673',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14796',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6477.873131',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14797',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11857.9718',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14798',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9851.937964',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14799',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16230.604',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14800',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '20233.17704',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14801',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10709.18107',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14802',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '8601.135033',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14803',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11091.98519',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14804',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4870.466197',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14805',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11142.78722',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14806',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4245.096384',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14807',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12293.90746',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14808',
    'Risk för ökat omsorgsbehov': '0.9',
    Resursbehov: '17463.68362',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14809',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '10450.00495',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14810',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7975.340374',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14811',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6928.659934',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14812',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '13303.77377',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14813',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11566.93562',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14814',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '11857.9718',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14815',
    'Risk för ökat omsorgsbehov': '0.6',
    Resursbehov: '12599.70689',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14816',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4051.567296',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14817',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24211.99382',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14818',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7516.923836',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14819',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7576.492617',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14820',
    'Risk för ökat omsorgsbehov': '2.8',
    Resursbehov: '57662.64701',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14821',
    'Risk för ökat omsorgsbehov': '2.4',
    Resursbehov: '48173.42991',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14822',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '15490.66934',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14823',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '9608.696565',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14824',
    'Risk för ökat omsorgsbehov': '3.7',
    Resursbehov: '74010.37913',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14825',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28334.08027',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14826',
    'Risk för ökat omsorgsbehov': '1.2',
    Resursbehov: '24135.5923',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14827',
    'Risk för ökat omsorgsbehov': '3.3',
    Resursbehov: '66563.99465',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '4',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '4',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14828',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '15081.60104',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14829',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '5309.732074',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14830',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '3709.983168',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '1',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14831',
    'Risk för ökat omsorgsbehov': '0.2',
    Resursbehov: '4383.806175',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Negativ',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '1',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '1',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14832',
    'Risk för ökat omsorgsbehov': '1.7',
    Resursbehov: '34488.04072',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14833',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19287.26642',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Avsett funktionstillstånd, Att förflytta sig själv': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14834',
    'Risk för ökat omsorgsbehov': '0.5',
    Resursbehov: '11004.7765',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Ja',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14835',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '27572.96506',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14836',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14229.56616',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14837',
    'Risk för ökat omsorgsbehov': '0.4',
    Resursbehov: '7302.119812',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14838',
    'Risk för ökat omsorgsbehov': '1.3',
    Resursbehov: '26073.3084',
    Ålder: '85-89',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14839',
    'Risk för ökat omsorgsbehov': '3.1',
    Resursbehov: '61939.41622',
    Ålder: '65-74',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '5',
    'Avsett funktionstillstånd, Känsla av trygghet': '3',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '5',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '5',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '3',
  },
  {
    Id: 'Individ14840',
    'Risk för ökat omsorgsbehov': '0.7',
    Resursbehov: '14394.0481',
    Ålder: '65-74',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14841',
    'Risk för ökat omsorgsbehov': '1.6',
    Resursbehov: '31709.06081',
    Ålder: '75-79',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Ingen trend',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '3',
    'Avsett funktionstillstånd, Känsla av trygghet': '2',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '4',
    'Avsett funktionstillstånd, Att förflytta sig själv': '3',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '3',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '2',
  },
  {
    Id: 'Individ14842',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '29344.93643',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Nej',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Ja',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14843',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16487.27388',
    Ålder: '90+',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14844',
    'Risk för ökat omsorgsbehov': '1.1',
    Resursbehov: '21495.57248',
    Ålder: '75-79',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Ja',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14845',
    'Risk för ökat omsorgsbehov': '1.0',
    Resursbehov: '19287.26642',
    Ålder: '80-84',
    Kön: 'Man',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Ja',
    'Trend omsorgsbehov 3mån': 'Negativ',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 2',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14846',
    'Risk för ökat omsorgsbehov': '0.3',
    Resursbehov: '6537.164241',
    Ålder: '90+',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Nej',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Nej',
    'Utförd insats av arbetsterapeut': 'Nej',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Ja',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Ja',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Avsett funktionstillstånd, Känsla av trygghet': 'Ej specificerat',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': 'Ej specificerat',
  },
  {
    Id: 'Individ14847',
    'Risk för ökat omsorgsbehov': '0.8',
    Resursbehov: '16760.94383',
    Ålder: '85-89',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Nej',
    Dagverksamhet: 'Ja',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Nej',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Positiv',
    'Utförd insats av fysioterapeut': 'Nej',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Ja',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Ja',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 1',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '3',
    'Avsett funktionstillstånd, Att förflytta sig själv': '2',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
  {
    Id: 'Individ14848',
    'Risk för ökat omsorgsbehov': '1.4',
    Resursbehov: '28748.95379',
    Ålder: '80-84',
    Kön: 'Kvinna',
    'Frånvaro på sjukhus': 'Ja',
    Fallolycka: 'Ja',
    'Kognitiv svikt': 'Ja',
    Dagverksamhet: 'Nej',
    Korttidsboende: 'Nej',
    'Social aktivitet': 'Ja',
    'Hög larmfrekvens, flagga': 'Nej',
    'Avböjda besök, flagga': 'Nej',
    'Trend omsorgsbehov 3mån': 'Positiv',
    'Trend omsorgsbehov 6mån': 'Ingen trend',
    'Utförd insats av fysioterapeut': 'Ja',
    'Utförd insats av sjuksköterska': 'Ja',
    'Utförd insats av arbetsterapeut': 'Ja',
    'Utförd insats av omvårdnadspersonal': 'Nej',
    'KVÅ - Balansträning': 'Nej',
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': 'Nej',
    'KVÅ - Kompressionsbehandling': 'Nej',
    'KVÅ - Nutritionsbehandling': 'Nej',
    'KVÅ - Omläggning av sår UNS': 'Nej',
    'Nivå hemsjukvård': 'Nivå 0',
    'Bedömt funktionstillstånd, Känsla av trygghet': '2',
    'Avsett funktionstillstånd, Känsla av trygghet': '1',
    'Bedömt funktionstillstånd, Att förflytta sig själv': '2',
    'Avsett funktionstillstånd, Att förflytta sig själv': '1',
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': '2',
    'Avsett funktionstillstånd, Att genomföra daglig rutin': '1',
  },
]
