import React, { useState } from 'react'
import Logo from '../../images/pejlen_liggande.svg'
import cryptoRandomString from 'crypto-random-string'
import { useLocation, useParams } from 'react-router-dom'
import { Button, Form, Input, Select, Spin, notification } from 'antd'
import '../../styles/app.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useAllowedAuthenticationMethods } from '../../api/tenant'
import { useMutation } from '@tanstack/react-query'

// export const useDeleteGuest = () => useMutation(['deleteGuest'], async (username: string) => await apiClient.deleteGuestUser(localStorage.getItem('tenant_id') ?? '', username))

const useGuestLogin = () =>
  useMutation(['guestLogin'], async (values: { password: string; username: string; login_challenge: string }) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/guest`, {
      username: values.username,
      password: values.password,
      login_challenge: values.login_challenge,
    })
  })

const getClientIdByKommun = (kommun: string): string => {
  switch (kommun) {
    case 'sollentuna':
      return process.env.REACT_APP_CLIENT_ID_SOLLENTUNA || ''
    case 'jonkoping':
      return process.env.REACT_APP_CLIENT_ID_JONKOPING || ''
    case 'norrkoping':
      return process.env.REACT_APP_CLIENT_ID_NORRKOPING || ''
    case 'hultsfred':
      return process.env.REACT_APP_CLIENT_ID_HULTSFRED || ''
    case 'dev':
      return process.env.REACT_APP_CLIENT_ID_DEV || ''
    case 'demo':
      return process.env.REACT_APP_CLIENT_ID_DEMO || ''
    default:
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        return process.env.REACT_APP_CLIENT_ID_DEV || ''
      }
      throw 'No client id for tenant'
  }
}

type LoginType = 'seid' | 'guest'

const Login: React.FC = () => {
  return (
    <div className="login-wrapper">
      <div className="logo">
        <img src={Logo} alt="Inrikta logga" width={'100%'} />
      </div>
      <LoginForm />
    </div>
  )
}

const LoginForm = () => {
  const location = useLocation()
  const { mutateAsync: loginGuest, isLoading: isGuestLoginLoading } = useGuestLogin()
  const kommun = window.location.href.split('.')[0].split('//')[1]
  const client_id = getClientIdByKommun(kommun)
  const { data: allowedAuthenticationMethods, isLoading: isAuthenticationMethodsLoading } = useAllowedAuthenticationMethods(client_id)
  const params = new URLSearchParams(location.search)

  const hydra_url = process.env.REACT_APP_HYDRA_URL
  const handleRedirect = (loginHint: LoginType) => async () => {
    const state = cryptoRandomString({ length: 32 })
    localStorage.setItem('oauth2_authentication_csrf', state)
    window.location.href = `${hydra_url}/oauth2/auth?client_id=${client_id}&response_type=code&scope=offline%20openid&state=${state}&login_hint=${loginHint}`
  }

  const sendGuestLogin = async ({ password, username }: { password: string; username: string }) => {
    try {
      const response = await loginGuest({ username, password, login_challenge: params.get('login_challenge') ?? '' })

      console.log(response)
      const redirectTo = response.data.redirect_to
      window.location.href = redirectTo
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Fel vid inloggning',
        description: 'Fel användarnamn eller lösenord',
      })
    }
  }

  if (params.get('guest'))
    return (
      <Form onFinish={(values) => sendGuestLogin(values)} layout="vertical">
        <Form.Item name="username" label="Användarnamn">
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Lösenord">
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={isGuestLoginLoading} type="primary">
            Logga in
          </Button>
          <Button onClick={() => window.history.back()} type="link">
            Tillbaka
          </Button>
        </Form.Item>
      </Form>
    )
  return (
    <div className="input-wrapper">
      {isAuthenticationMethodsLoading && (
        <div className="flex h-full items-center justify-center">
          <Spin spinning={true} />
        </div>
      )}

      {allowedAuthenticationMethods?.includes('SEID') && (
        <Button onClick={handleRedirect('seid')} style={{ width: '100%', marginTop: 10 }}>
          Logga in med svensk e-identitet
        </Button>
      )}

      {allowedAuthenticationMethods?.includes('basic') && (
        <Button onClick={handleRedirect('guest')} style={{ width: '100%', marginTop: 10 }}>
          Logga in som gäst
        </Button>
      )}
      <div className="login-error"></div>
    </div>
  )
}

export default Login
