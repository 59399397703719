import { TableColumnsType } from 'antd'
import { DataType } from './types'

export const forebyggandeInsatserColumnDefs: TableColumnsType<DataType> = [
  {
    title: 'Id',
    dataIndex: 'Id',
    key: 'Id',
    ellipsis: true,
    sorter: (a, b) => a.Id.localeCompare(b.Id),
  },
  {
    title: 'Risk för ökat omsorgsbehov',
    dataIndex: 'Risk för ökat omsorgsbehov',
    key: 'Risk för ökat omsorgsbehov',
    ellipsis: true,
    sorter: (a, b) => Number(a['Risk för ökat omsorgsbehov']) - Number(b['Risk för ökat omsorgsbehov']),
  },
  {
    title: 'Resursbehov',
    dataIndex: 'Resursbehov',
    key: 'Resursbehov',
    ellipsis: true,
    sorter: (a, b) => Number(a.Resursbehov) - Number(b.Resursbehov),
  },
  {
    title: 'Ålder',
    dataIndex: 'Ålder',
    key: 'Ålder',
    ellipsis: true,
    sorter: (a, b) => a.Ålder.localeCompare(b.Ålder),
  },
  {
    title: 'Kön',
    dataIndex: 'Kön',
    key: 'Kön',
    ellipsis: true,
    sorter: (a, b) => a.Kön.localeCompare(b.Kön),
  },
  {
    title: 'Trend omsorgsbehov 3mån',
    dataIndex: 'Trend omsorgsbehov 3mån',
    key: 'Trend omsorgsbehov 3mån',
    ellipsis: true,
    sorter: (a, b) => a['Trend omsorgsbehov 3mån'].localeCompare(b['Trend omsorgsbehov 3mån']),
  },
  {
    title: 'Trend omsorgsbehov 6mån',
    dataIndex: 'Trend omsorgsbehov 6mån',
    key: 'Trend omsorgsbehov 6mån',
    ellipsis: true,
    sorter: (a, b) => a['Trend omsorgsbehov 6mån'].localeCompare(b['Trend omsorgsbehov 6mån']),
  },
  {
    title: 'Frånvaro på sjukhus',
    dataIndex: 'Frånvaro på sjukhus',
    key: 'Frånvaro på sjukhus',
    ellipsis: true,
    sorter: (a, b) => a['Frånvaro på sjukhus'].localeCompare(b['Frånvaro på sjukhus']),
  },
  {
    title: 'Fallolycka',
    dataIndex: 'Fallolycka',
    key: 'Fallolycka',
    ellipsis: true,
    sorter: (a, b) => a.Fallolycka.localeCompare(b.Fallolycka),
  },
  {
    title: 'Kognitiv svikt',
    dataIndex: 'Kognitiv svikt',
    key: 'Kognitiv svikt',
    ellipsis: true,
    sorter: (a, b) => a['Kognitiv svikt'].localeCompare(b['Kognitiv svikt']),
  },
  {
    title: 'Dagverksamhet',
    dataIndex: 'Dagverksamhet',
    key: 'Dagverksamhet',
    ellipsis: true,
    sorter: (a, b) => a.Dagverksamhet.localeCompare(b.Dagverksamhet),
  },
  {
    title: 'Korttidsboende',
    dataIndex: 'Korttidsboende',
    key: 'Korttidsboende',
    ellipsis: true,
    sorter: (a, b) => a.Korttidsboende.localeCompare(b.Korttidsboende),
  },
  {
    title: 'Social aktivitet',
    dataIndex: 'Social aktivitet',
    key: 'Social aktivitet',
    ellipsis: true,
    sorter: (a, b) => a['Social aktivitet'].localeCompare(b['Social aktivitet']),
  },
  {
    title: 'KVÅ - Balansträning',
    dataIndex: 'KVÅ - Balansträning',
    key: 'KVÅ - Balansträning',
    ellipsis: true,
    sorter: (a, b) => a['KVÅ - Balansträning'].localeCompare(b['KVÅ - Balansträning']),
  },
  {
    title: 'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet',
    dataIndex: 'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet',
    key: 'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet',
    ellipsis: true,
    sorter: (a, b) => a['KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet'].localeCompare(b['KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet']),
  },
  {
    title: 'KVÅ - Kompressionsbehandling',
    dataIndex: 'KVÅ - Kompressionsbehandling',
    key: 'KVÅ - Kompressionsbehandling',
    ellipsis: true,
    sorter: (a, b) => a['KVÅ - Kompressionsbehandling'].localeCompare(b['KVÅ - Kompressionsbehandling']),
  },
  {
    title: 'KVÅ - Nutritionsbehandling',
    dataIndex: 'KVÅ - Nutritionsbehandling',
    key: 'KVÅ - Nutritionsbehandling',
    ellipsis: true,
    sorter: (a, b) => a['KVÅ - Nutritionsbehandling'].localeCompare(b['KVÅ - Nutritionsbehandling']),
  },
  {
    title: 'KVÅ - Omläggning av sår UNS',
    dataIndex: 'KVÅ - Omläggning av sår UNS',
    key: 'KVÅ - Omläggning av sår UNS',
    ellipsis: true,
    sorter: (a, b) => a['KVÅ - Omläggning av sår UNS'].localeCompare(b['KVÅ - Omläggning av sår UNS']),
  },
  {
    title: 'Hög larmfrekvens, flagga',
    dataIndex: 'Hög larmfrekvens, flagga',
    key: 'Hög larmfrekvens, flagga',
    ellipsis: true,
    sorter: (a, b) => a['Hög larmfrekvens, flagga'].localeCompare(b['Hög larmfrekvens, flagga']),
  },
  {
    title: 'Avböjda besök, flagga',
    dataIndex: 'Avböjda besök, flagga',
    key: 'Avböjda besök, flagga',
    ellipsis: true,
    sorter: (a, b) => a['Avböjda besök, flagga'].localeCompare(b['Avböjda besök, flagga']),
  },
  {
    title: 'Utförd insats av fysioterapeut',
    dataIndex: 'Utförd insats av fysioterapeut',
    key: 'Utförd insats av fysioterapeut',
    ellipsis: true,
    sorter: (a, b) => a['Utförd insats av fysioterapeut'].localeCompare(b['Utförd insats av fysioterapeut']),
  },
  {
    title: 'Utförd insats av sjuksköterska',
    dataIndex: 'Utförd insats av sjuksköterska',
    key: 'Utförd insats av sjuksköterska',
    ellipsis: true,
    sorter: (a, b) => a['Utförd insats av sjuksköterska'].localeCompare(b['Utförd insats av sjuksköterska']),
  },
  {
    title: 'Utförd insats av arbetsterapeut',
    dataIndex: 'Utförd insats av arbetsterapeut',
    key: 'Utförd insats av arbetsterapeut',
    ellipsis: true,
    sorter: (a, b) => a['Utförd insats av arbetsterapeut'].localeCompare(b['Utförd insats av arbetsterapeut']),
  },
  {
    title: 'Utförd insats av omvårdnadspersonal',
    dataIndex: 'Utförd insats av omvårdnadspersonal',
    key: 'Utförd insats av omvårdnadspersonal',
    ellipsis: true,
    sorter: (a, b) => a['Utförd insats av omvårdnadspersonal'].localeCompare(b['Utförd insats av omvårdnadspersonal']),
  },
  {
    title: 'Nivå hemsjukvård',
    dataIndex: 'Nivå hemsjukvård',
    key: 'Nivå hemsjukvård',
    ellipsis: true,
    sorter: (a, b) => a['Nivå hemsjukvård'].localeCompare(b['Nivå hemsjukvård']),
  },
  {
    title: 'Bedömt funktionstillstånd, Känsla av trygghet',
    dataIndex: 'Bedömt funktionstillstånd, Känsla av trygghet',
    key: 'Bedömt funktionstillstånd, Känsla av trygghet',
    ellipsis: true,
    sorter: (a, b) => a['Bedömt funktionstillstånd, Känsla av trygghet'].localeCompare(b['Bedömt funktionstillstånd, Känsla av trygghet']),
  },
  {
    title: 'Avsett funktionstillstånd, Känsla av trygghet',
    dataIndex: 'Avsett funktionstillstånd, Känsla av trygghet',
    key: 'Avsett funktionstillstånd, Känsla av trygghet',
    ellipsis: true,
    sorter: (a, b) => a['Avsett funktionstillstånd, Känsla av trygghet'].localeCompare(b['Avsett funktionstillstånd, Känsla av trygghet']),
  },
  {
    title: 'Bedömt funktionstillstånd, Att förflytta sig själv',
    dataIndex: 'Bedömt funktionstillstånd, Att förflytta sig själv',
    key: 'Bedömt funktionstillstånd, Att förflytta sig själv',
    ellipsis: true,
    sorter: (a, b) => a['Bedömt funktionstillstånd, Att förflytta sig själv'].localeCompare(b['Bedömt funktionstillstånd, Att förflytta sig själv']),
  },
  {
    title: 'Avsett funktionstillstånd, Att förflytta sig själv',
    dataIndex: 'Avsett funktionstillstånd, Att förflytta sig själv',
    key: 'Avsett funktionstillstånd, Att förflytta sig själv',
    ellipsis: true,
    sorter: (a, b) => a['Avsett funktionstillstånd, Att förflytta sig själv'].localeCompare(b['Avsett funktionstillstånd, Att förflytta sig själv']),
  },
  {
    title: 'Bedömt funktionstillstånd, Att genomföra daglig rutin',
    dataIndex: 'Bedömt funktionstillstånd, Att genomföra daglig rutin',
    key: 'Bedömt funktionstillstånd, Att genomföra daglig rutin',
    ellipsis: true,
    sorter: (a, b) => a['Bedömt funktionstillstånd, Att genomföra daglig rutin'].localeCompare(b['Bedömt funktionstillstånd, Att genomföra daglig rutin']),
  },
  {
    title: 'Avsett funktionstillstånd, Att genomföra daglig rutin',
    dataIndex: 'Avsett funktionstillstånd, Att genomföra daglig rutin',
    key: 'Avsett funktionstillstånd, Att genomföra daglig rutin',
    ellipsis: true,
    sorter: (a, b) => a['Avsett funktionstillstånd, Att genomföra daglig rutin'].localeCompare(b['Avsett funktionstillstånd, Att genomföra daglig rutin']),
  },
]
