// Antd
import { Button, Checkbox, Divider, Form, FormProps, InputNumber, Select, Tooltip, Typography } from 'antd'
import { FC, Fragment, useState } from 'react'
import { DagligVerksamhetFormValues, DagligVerksamhetInputTypes, DagligVerksamhetLevels, DagligVerksamhetLevelsExtended } from './types'
import { tooltipTexts } from './utility/tooltipTexts'

const { Option } = Select
const { Text } = Typography

type Props = {
  onFinish: (values: DagligVerksamhetFormValues) => void
  onFinishFailed: FormProps<DagligVerksamhetFormValues>['onFinishFailed']
}

const DagligVerksamhetInput: FC<Props> = ({ onFinish, onFinishFailed }) => {
  return (
    <div className="flex flex-col gap-8">
      <Form<DagligVerksamhetFormValues> name={'korttid-input'} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div className="grid grid-cols-2 items-end gap-9">
          <Typography.Title level={5}>Behovsområde</Typography.Title>
          <Typography.Title level={5}>Nivå</Typography.Title>
        </div>
        <Divider style={{ width: '100%', marginTop: 12, marginBottom: 0 }} />
        {Object.values(DagligVerksamhetInputTypes).map((item, index) => (
          <Fragment key={`${item}-${index}`}>
            <div className="grid grid-cols-2  gap-4" key={index}>
              <div className="pt-1">
                <Typography.Text>{item}</Typography.Text>
              </div>
              <Form.Item<DagligVerksamhetFormValues> name={item} rules={[{ required: true, message: 'Vänligen välj ett alternativ' }]}>
                <Select bordered={false} placeholder="Välj nivå" allowClear>
                  {Object.values([DagligVerksamhetInputTypes.Psykomotorisk].includes(item as any) ? DagligVerksamhetLevelsExtended : DagligVerksamhetLevels).map((level) => (
                    <Option key={`${item}-${level}`} value={level}>
                      <Tooltip title={tooltipTexts[item][level]} placement="right">
                        <div style={{ width: '100%' }}>{level}</div>
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Divider style={{ width: '100%', marginBlock: 1 }} />
          </Fragment>
        ))}
        <div className="mt-8 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <Form.Item<DagligVerksamhetFormValues> name={'hoursPerMonth'} rules={[{ required: true, message: 'Värde krävs' }]}>
              <InputNumber type="number" style={{ width: '250px' }} placeholder="Antal timmar per månad" />
            </Form.Item>
            <Typography.Text italic>Justering fler/färre timmar</Typography.Text>
            <Form.Item<DagligVerksamhetFormValues> name={'extraHours'}>
              <InputNumber type="number" min={-168} max={168} style={{ width: '250px' }} placeholder="Extratimmar per vecka" />
            </Form.Item>
          </div>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit" className="w-[150px]">
              Beräkna
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default DagligVerksamhetInput
