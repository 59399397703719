export const KorttidCalculationType = [
  'Timmar från grundschema',
  'Vardag dag',
  'Vardag kväll',
  'Helg dag',
  'Helg kväll',
  'Natt',
  'Extratimmar från bedömning',
  'Extratimmar från justering',
  'Totalt antal timmar',
] as const

export const ActivityTimeframes = {
  'Vardag dagtid': 'Vardag dagtid',
  'Vardag kväll': 'Vardag kväll',
  'Helg dagtid': 'Helg dagtid',
  'Helg kväll': 'Helg kväll',
  Natt: 'Natt',
  'Extrakostnader per dygn': 'Extrakostnader per dygn',
} as const

export const KorttidInputTypes = {
  Förflyttning: 'Förflyttning',
  Kommunikation: 'Kommunikation',
  'Social Interaktion': 'Social Interaktion',
  'Personlig vård': 'Personlig vård',
  Hemliv: 'Hemliv',
  Fritid: 'Fritid',
  'Daglig verksamhet': 'Daglig verksamhet',
  Psykomotorisk: 'Psykomotorisk',
  Natt: 'Natt',
  'HSL Ledsagning': 'HSL Ledsagning',
} as const

export type KorttidInputTypes = (typeof KorttidInputTypes)[keyof typeof KorttidInputTypes]

export const KorttidLevels = { '1': '1', '2': '2', '3': '3', '4': '4', 'Vet ej': 'Vet ej' } as const

export const KorttidLevelsExtended = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', 'Vet ej': 'Vet ej' } as const

export type KorttidFormValues = {
  Förflyttning: keyof typeof KorttidLevels
  Kommunikation: keyof typeof KorttidLevels
  'Social Interaktion': keyof typeof KorttidLevels
  'Personlig vård': keyof typeof KorttidLevels
  Hemliv: keyof typeof KorttidLevels
  Fritid: keyof typeof KorttidLevels
  'Daglig verksamhet': keyof typeof KorttidLevelsExtended
  Psykomotorisk: keyof typeof KorttidLevelsExtended
  Natt: keyof typeof KorttidLevels
  'HSL Ledsagning': keyof typeof KorttidLevels
  extraHours?: number
  vardagsDygnPerMonth?: number
  helgdagsDygnPerMonth?: number
}

export const KorttidCalculationTimePeriod = {
  Vardag: 'Vardag',
  Helgdag: 'Helgdag',
  Vecka: 'Vecka',
  Månad: 'Månad',
  'Snitt/dygn': 'Snitt/dygn',
} as const
