import { TeamOutlined } from '@ant-design/icons'
import React from 'react'
import { SiderMenuNames } from './siderMenuDataset'
import { IHandläggare } from '../../../store/organizationSlice'

export class PermissionTypes {
  // Användare
  static användarhantering = 'användarhantering'

  // Handläggning
  static mina_ärenden = 'mina_ärenden'
  static ärendefördelning = 'ärendefördelning'
  static volymuppföljning = 'volymuppföljning'
  static utfanför_teamstruktur = 'utanför_teamstruktur'
  static nyligen_utgångna = 'nyligen_utgångna'

  // Flags
  static brukare = 'brukare'
  static personal = 'personal'
  static utförare = 'utförare'
  static uppföljningslista = 'uppföljningslista'
  static forebyggande_insatser = 'forebyggande_insatser'

  // Hemtjänst
  static uppföljning_statistik = 'uppföljning_statistik'

  // Benchmarking
  static benchmarking = 'benchmarking'

  // Beslutsstöd
  static edit_beslutsstöd = 'edit_beslutsstöd'
  static view_beslutsstöd = 'view_beslutsstöd'
  static edit_ibic_beslutsstöd = 'edit_ibic_beslutsstöd'
  static view_ibic_beslutsstöd = 'view_ibic_beslutsstöd'
  static edit_lss_beslutsstöd = 'edit_lss_beslutsstöd'
  static view_lss_beslutsstöd = 'view_lss_beslutsstöd'

  // Administration
  static administration = 'administration'
}

const getKommunNameByTenantId = (tenant_id: string | null) => {
  if (!tenant_id) return ''
  switch (tenant_id) {
    case process.env.REACT_APP_CLIENT_ID_SOLLENTUNA:
      return 'Sollentuna'
    case process.env.REACT_APP_CLIENT_ID_JONKOPING:
      return 'Jönköping'
    default:
      return ''
  }
}

const generateSideMenuChildren = (me: IHandläggare, dataSet: SiderMenuNames, isArandeFordelning = false) => {
  const currentLabel = me.permissions

  const children = currentLabel.map((item: string) => {
    if (item in dataSet) {
      let label: string
      const kommun = getKommunNameByTenantId(localStorage.getItem('tenant_id'))
      if (item === 'benchmarking') label = kommun.charAt(0).toUpperCase() + kommun.slice(1)
      if (item === 'uppföljningslista') label = 'Tilldelade avvikelser'
      else label = isArandeFordelning ? 'Mina ärenden/Volymuppföljning' : item.charAt(0).toUpperCase() + item.slice(1)
      return {
        key: dataSet[item],
        icon: React.createElement(TeamOutlined),
        label,
      }
    } else {
      return null
    }
  })

  return children
}

export default generateSideMenuChildren
