import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../useApi'

export const useGenerateLSSCsv = () => {
  return useMutation({
    mutationFn: async () => {
      const res = await apiClient.exportBeslutsstodLSSCsv(localStorage.getItem('tenant_id') as string)
      return res.data
    },
  })
}
