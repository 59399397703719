import { Button, Collapse, notification, Table, Typography } from 'antd'
import React from 'react'
import { forebyggandeInsatserColumnDefs } from './forebyggandeInsatserColumnDefs'
import { ForebyggandeInsatserFilters } from './forebyggandeInsatserFilters'
import { DataType } from './types'
import { useForebyggandeInsatserTable } from './useForebyggandeInsatserTable'
import { hardCodedTableData } from './hardCodedTableData'
import FlagListHeader from '../../components/header'

const ForebyggandeInsatserTable: React.FC = () => {
  const { filteredTableData, filteredInfo, setFilteredInfo, saveFavoriteFilters, activeFilters, resetFilters, options } = useForebyggandeInsatserTable()
  const [notificationInstance, contextHolder] = notification.useNotification()

  return (
    <div>
      <FlagListHeader heading={'Förebyggande insatser'} title="" description={null} button={false} />
      {contextHolder}
      <div className="my-4 flex flex-wrap items-center gap-4">
        <Collapse expandIconPosition="end">
          <Collapse.Panel header={`Filtrera urval (${activeFilters.length} av ${Object.keys(filteredInfo).length} filter aktiva)`} key="1">
            <ForebyggandeInsatserFilters setFilteredInfo={setFilteredInfo} filteredInfo={filteredInfo} options={options} />
          </Collapse.Panel>
        </Collapse>
        <div className="flex items-center gap-4">
          <Button
            type="primary"
            onClick={() => {
              saveFavoriteFilters()
              notificationInstance.success({
                message: 'Filterurval sparat',
              })
            }}
          >
            Spara urval
          </Button>
          <Button type="primary" onClick={() => resetFilters()}>
            Rensa filter
          </Button>
          <Typography.Text strong>
            {filteredTableData.length} av {hardCodedTableData.length} rader matchar aktiva filter
          </Typography.Text>
        </div>
      </div>
      <div style={{ height: '100%' }}>
        <Table<DataType>
          scroll={{ x: true }}
          columns={forebyggandeInsatserColumnDefs}
          dataSource={filteredTableData}
          summary={() => {
            let totalRisk = 0
            let totalResourceNeed = 0

            filteredTableData.forEach((data) => {
              totalRisk += Number(data['Risk för ökat omsorgsbehov'])
              totalResourceNeed += Number(data.Resursbehov)
            })

            return (
              <>
                <Table.Summary.Row className="bg-gray-50">
                  <Table.Summary.Cell index={0} className="font-bold">
                    Medel
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{(totalRisk / filteredTableData.length).toFixed(1)}</Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={999}>
                    {(totalResourceNeed / filteredTableData.length).toFixed(1)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row className="bg-gray-50">
                  <Table.Summary.Cell index={0} className="font-bold">
                    Summa
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{totalRisk.toFixed(1)}</Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={999} index={2}>
                    {totalResourceNeed.toFixed(1)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </div>
    </div>
  )
}

export default ForebyggandeInsatserTable
