import { Divider, FormProps } from 'antd'
import React, { useState } from 'react'
import KorttidCalculation from './KorttidCalculation'
import KorttidInput from './KorttidInput'
import { KorttidFormValues } from './types'

type Props = {}

const Korttid: React.FC<Props> = (props) => {
  const [submitData, setSubmitData] = useState<KorttidFormValues | null>(null)

  const onFinish = (values: KorttidFormValues) => {
    setSubmitData(values)
  }
  const onFinishFailed: FormProps<KorttidFormValues>['onFinishFailed'] = (error) => {
    console.error(error)
    setSubmitData(null)
  }

  return (
    <div className="grid grid-cols-[40%,96px,auto] gap-4">
      <KorttidInput onFinish={onFinish} onFinishFailed={onFinishFailed} />
      <Divider type="vertical" style={{ height: '100%', margin: 'auto', borderLeft: '1px solid black' }} />

      {submitData && <KorttidCalculation data={submitData} />}
    </div>
  )
}

export default Korttid
