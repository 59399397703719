export const DagligVerksamhetCalculationType = [
  'Timmar från grundschema',
  'Vardag dag',
  'Vardag kväll',
  'Helg dag',
  'Helg kväll',
  'Natt',
  'Extratimmar från bedömning',
  'Extratimmar från justering',
  'Totalt antal timmar',
] as const

export const ActivityTimeframes = {
  'Vardag dagtid': 'Vardag dagtid',
  'Vardag kväll': 'Vardag kväll',
  'Helg dagtid': 'Helg dagtid',
  'Helg kväll': 'Helg kväll',
  Natt: 'Natt',
  'Extrakostnader per dygn': 'Extrakostnader per dygn',
} as const

export const DagligVerksamhetInputTypes = {
  Förflyttning: 'Förflyttning',
  Kommunikation: 'Kommunikation',
  'Social Interaktion': 'Social Interaktion',
  'Personlig vård': 'Personlig vård',
  Hemliv: 'Hemliv',
  Fritid: 'Fritid',
  Psykomotorisk: 'Psykomotorisk',
  Natt: 'Natt',
  'HSL Ledsagning': 'HSL Ledsagning',
} as const

export type DagligVerksamhetInputTypes = (typeof DagligVerksamhetInputTypes)[keyof typeof DagligVerksamhetInputTypes]

export const DagligVerksamhetLevels = { '1': '1', '2': '2', '3': '3', '4': '4', 'Vet ej': 'Vet ej' } as const

export const DagligVerksamhetLevelsExtended = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', 'Vet ej': 'Vet ej' } as const

export type DagligVerksamhetFormValues = {
  Förflyttning: keyof typeof DagligVerksamhetLevels
  Kommunikation: keyof typeof DagligVerksamhetLevels
  'Social Interaktion': keyof typeof DagligVerksamhetLevels
  'Personlig vård': keyof typeof DagligVerksamhetLevels
  Hemliv: keyof typeof DagligVerksamhetLevels
  Fritid: keyof typeof DagligVerksamhetLevels
  Psykomotorisk: keyof typeof DagligVerksamhetLevelsExtended
  Natt: keyof typeof DagligVerksamhetLevels
  'HSL Ledsagning': keyof typeof DagligVerksamhetLevels
  extraHours?: number
  hoursPerMonth: number
}

export const DagligVerksamhetCalculationTimePeriod = {
  Vardag: 'Vardag',
  Helgdag: 'Helgdag',
  Vecka: 'Vecka',
  Månad: 'Månad',
  'Snitt/dygn': 'Snitt/dygn',
} as const
