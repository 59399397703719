import { averageHoursPerWeekWeekday, averageHoursPerWeekWeekend, basePrice, hoursBaseSchedule, DagligVerksamhetTypesLevelMapping } from './calculationConstants'
import { DagligVerksamhetFormValues, DagligVerksamhetCalculationTimePeriod, DagligVerksamhetInputTypes } from '../types'

export const calculateTableData = (
  data: DagligVerksamhetFormValues
): {
  numberOfYearlyWorkers: number
  compensationPerMonth: number
} => {
  const grundSchema: Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number> = {
    Vardag: averageHoursPerWeekWeekday / 6,
    Helgdag: averageHoursPerWeekWeekend / 6,
    Vecka: hoursBaseSchedule / 6,
    Månad: (hoursBaseSchedule / 6) * 4,
    'Snitt/dygn': hoursBaseSchedule / 6 / 7,
  }

  let includeAllAreas = true

  if (data.Psykomotorisk === '4' || data.Psykomotorisk === '5') {
    includeAllAreas = false
  }

  const excludedAreas = [
    DagligVerksamhetInputTypes.Förflyttning,
    DagligVerksamhetInputTypes.Kommunikation,
    DagligVerksamhetInputTypes['Social Interaktion'],
    DagligVerksamhetInputTypes['Personlig vård'],
    DagligVerksamhetInputTypes.Hemliv,
    DagligVerksamhetInputTypes.Fritid,
  ]

  const accumulatedLevelHours = Object.entries(data).reduce(
    (acc, [key, value]) => {
      if ((!includeAllAreas && excludedAreas.includes(key as any)) || typeof value === 'number' || !value) {
        return acc
      }

      const currentType = DagligVerksamhetTypesLevelMapping[key as keyof typeof DagligVerksamhetInputTypes]
      const currentLevel = '5' in currentType ? currentType[value] : value !== '5' ? currentType[value] : undefined

      const vardagDagtid = currentLevel?.['Vardag dagtid'] || 0
      const vardagKväll = currentLevel?.['Vardag kväll'] || 0
      const helgDagtid = currentLevel?.['Helg dagtid'] || 0
      const helgKväll = currentLevel?.['Helg kväll'] || 0
      const natt = currentLevel?.Natt || 0
      const extrakostnader = currentLevel?.['Extrakostnader per dygn'] || 0

      return {
        'Vardag dag': {
          Vardag: acc['Vardag dag']['Vardag'] + vardagDagtid,
          Helgdag: 0,
          Vecka: acc['Vardag dag']['Vecka'] + vardagDagtid * 5,
          Månad: acc['Vardag dag']['Månad'] + vardagDagtid * 5 * 4,
          'Snitt/dygn': acc['Vardag dag']['Snitt/dygn'] + (vardagDagtid * 5) / 7,
        },
        'Vardag kväll': {
          Vardag: acc['Vardag kväll']['Vardag'] + vardagKväll,
          Helgdag: 0,
          Vecka: acc['Vardag kväll']['Vecka'] + vardagKväll * 5,
          Månad: acc['Vardag kväll']['Månad'] + vardagKväll * 5 * 4,
          'Snitt/dygn': acc['Vardag kväll']['Snitt/dygn'] + (vardagKväll * 5) / 7,
        },
        'Helg dag': {
          Vardag: 0,
          Helgdag: acc['Helg dag']['Helgdag'] + helgDagtid,
          Vecka: acc['Helg dag']['Vecka'] + helgDagtid * 2,
          Månad: acc['Helg dag']['Månad'] + helgDagtid * 2 * 4,
          'Snitt/dygn': acc['Helg dag']['Snitt/dygn'] + (helgDagtid * 2) / 7,
        },
        'Helg kväll': {
          Vardag: 0,
          Helgdag: acc['Helg kväll']['Helgdag'] + helgKväll,
          Vecka: acc['Helg kväll']['Vecka'] + helgKväll * 2,
          Månad: acc['Helg kväll']['Månad'] + helgKväll * 2 * 4,
          'Snitt/dygn': acc['Helg kväll']['Snitt/dygn'] + (helgKväll * 2) / 7,
        },
        Natt: {
          Vardag: acc['Natt']['Vardag'] + natt,
          Helgdag: acc['Natt']['Helgdag'] + natt,
          Vecka: acc['Natt']['Vecka'] + natt * 7,
          Månad: acc['Natt']['Månad'] + natt * 7 * 4,
          'Snitt/dygn': acc['Natt']['Snitt/dygn'] + natt,
        },
        'Extratimmar från bedömning': {
          Vardag: acc['Extratimmar från bedömning']['Vardag'] + vardagDagtid + vardagKväll + natt,
          Helgdag: acc['Extratimmar från bedömning']['Helgdag'] + helgDagtid + helgKväll + natt,
          Vecka: acc['Extratimmar från bedömning']['Vecka'] + ((vardagDagtid + vardagKväll) * 5 + (helgDagtid + helgKväll) * 2 + natt * 7),
          Månad: acc['Extratimmar från bedömning']['Månad'] + ((vardagDagtid + vardagKväll) * 5 + (helgDagtid + helgKväll) * 2 + natt * 7) * 4,
          'Snitt/dygn': acc['Extratimmar från bedömning']['Snitt/dygn'] + ((vardagDagtid + vardagKväll) * 5 + (helgDagtid + helgKväll) * 2 + natt * 7) / 7,
          Ersättning: acc['Extratimmar från bedömning']['Ersättning'] + extrakostnader,
        },
      }
    },
    {
      'Vardag dag': {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
      },
      'Vardag kväll': {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
      },
      'Helg dag': {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
      },
      'Helg kväll': {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
      },
      Natt: {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
      },
      'Extratimmar från bedömning': {
        Vardag: 0,
        Helgdag: 0,
        Vecka: 0,
        Månad: 0,
        'Snitt/dygn': 0,
        Ersättning: 0,
      },
    } as {
      'Vardag dag': Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number>
      'Vardag kväll': Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number>
      'Helg dag': Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number>
      'Helg kväll': Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number>
      Natt: Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number>
      'Extratimmar från bedömning': Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number> & { Ersättning: number }
    }
  )

  // const extraTimmarJustering: Record<keyof typeof DagligVerksamhetCalculationTimePeriod, number> = {
  //   Vardag: (data.extraHours || 0) / 7,
  //   Helgdag: (data.extraHours || 0) / 7,
  //   Vecka: data.extraHours || 0,
  //   Månad: (data.extraHours || 0) * 4,
  //   'Snitt/dygn': (data.extraHours || 0) / 7,
  // }

  // const totalHours = {
  //   Vardag: grundSchema.Vardag + accumulatedLevelHours['Extratimmar från bedömning'].Vardag + extraTimmarJustering.Vardag,
  //   Helgdag: grundSchema.Helgdag + accumulatedLevelHours['Extratimmar från bedömning'].Helgdag + extraTimmarJustering.Helgdag,
  //   Vecka: grundSchema.Vecka + accumulatedLevelHours['Extratimmar från bedömning'].Vecka + extraTimmarJustering.Vecka,
  //   Månad: grundSchema.Månad + accumulatedLevelHours['Extratimmar från bedömning'].Månad + extraTimmarJustering.Månad,
  //   'Snitt/dygn': grundSchema['Snitt/dygn'] + accumulatedLevelHours['Extratimmar från bedömning']['Snitt/dygn'] + extraTimmarJustering['Snitt/dygn'],
  // }

  // const numberOfYearlyWorkers = totalHours.Vecka / (271 / 7)

  const compensationPerDay = basePrice + accumulatedLevelHours['Extratimmar från bedömning'].Ersättning * (1 - 0.18) + (((data.extraHours || 0) * 369) / 7) * (1 - 0.18)

  console.log(`Kompensation per dag: ${compensationPerDay}`)

  const compensationPerHour = (() => {
    if (compensationPerDay <= 2979) return 121.896
    if (compensationPerDay <= 3921) return 200.488
    if (compensationPerDay <= 5316) return 317.992
    return 513.912
  })()

  const compensationPerMonth = compensationPerHour * data.hoursPerMonth

  return {
    numberOfYearlyWorkers: compensationPerMonth / 325 / 160,
    compensationPerMonth: compensationPerMonth,
  }
}
