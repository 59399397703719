import { KorttidInputTypes, KorttidLevelsExtended } from '../types'

export const tooltipTexts: Record<KorttidInputTypes, Record<keyof typeof KorttidLevelsExtended, string>> = {
  Förflyttning: {
    'Vet ej': '',
    '1': 'Kan förflytta sig i kända miljöer. Kan behöva instruktioner i nya miljöer',
    '2': 'Kan förflytta sig i kända miljöer. Behöver personalstöd i nya miljöer',
    '3': 'Behöver personalstöd vid alla förflyttningar utomhus. Kan behöva hjälp inomhus.',
    '4': 'Behöver personalstöd vid alla förflyttningar, utomhus och inomhus. Ibland dubbelbemanning.',
    '5': '',
  },
  Kommunikation: {
    'Vet ej': '',
    '1': 'Behöver stöd i att förstå instruktioner och information. Behöver personalstöd vid skriftlig information',
    '2': 'Svårigheter med läs- och skrivförmåga, samt litet ordförråd. Förstår samtal av vardaglig karaktär.',
    '3': 'Använder enstaka ord. Behov av alternativ kommunikation. Förstår enkel information.',
    '4': 'Behöver stöd vid kommunikation för att göra sig förstådd. Alternativt kommunicerar inte med vedertagna tecken eller ord.',
    '5': '',
  },
  'Social Interaktion': {
    'Vet ej': '',
    '1': 'Kan behöva visst stöd i nya sociala relationer. Klarar sig i huvudsak själv.',
    '2': 'Kan behöva visst stöd för att förstå och tolka sociala sammanhang.',
    '3': 'Behöver i huvudsak stöd för att förstå och tolka sociala sammanhang, samt reda upp missförstånd.',
    '4': 'Behöver alltid stöd för att förstå och tolka sociala sammanhang. Inga egna initiativ till socialt umgänge.',
    '5': '',
  },
  'Personlig vård': {
    'Vet ej': '',
    '1': 'Personalstöd i att komma ihåg hygien och kläder för väder. Kan äta, duscha och dricka självständigt.',
    '2': 'Visst stöd i form av påminnelser vid hygien (ex rakning, tandborstning). Kan behöva hjälp med framläggning av kläder.',
    '3': 'Personalstöd och praktisk hjälp med hygien och påklädning',
    '4': 'Konstant stöd avseende hygien och kläder. Hjälp med matning',
    '5': '',
  },
  Hemliv: {
    'Vet ej': '',
    '1': 'Visst personalstöd i form av påminnelser/planering (ex inköp/tvätt) kan laga mat självständigt',
    '2': 'Personal är med vid utförandet men den enskilde utför aktiviteten',
    '3': 'Personal utför aktiviteterna med viss delaktighet från den enskilde',
    '4': 'Den enskilde har inte förmågan att utföra aktiviteter i hemmet',
    '5': '',
  },
  Fritid: {
    'Vet ej': '',
    '1': 'Kan behöva förslag och hjälp med planering från personal men utför aktiviteterna självständigt',
    '2': 'Personal är stöd vid nya okända aktiviteter men klarar att utföra vana aktiviteter självständigt.',
    '3': 'Personalstöd i form av närvaro vid utförandet av aktiviteter utanför hemmet',
    '4': 'Personalstöd vid samtliga fritidsaktiviteter, i och utanför hemmet.',
    '5': '',
  },
  'Daglig verksamhet': {
    'Vet ej': '',
    '1': 'Går heltid på DV eller hemma någon gång ibland',
    '2': 'Går ca halvtid på DV',
    '3': 'Går på DV ett fåtal timmar per vecka.',
    '4': 'Hemma heltid eller behov av specialanpassad DV i hemmet (1:1)',
    '5': 'Hemma heltid eller behov av specialanpassad DV i hemmet (2:1)',
  },
  'HSL Ledsagning': {
    'Vet ej': '',
    '1': 'Dagligt HSL-stöd vid medicinering/omläggning på delegation.',
    '2': 'Stöd vid regelbundna HSL-besök och eventuell ordination från fysioterapeut utöver de vardagliga HSL-stöd vid delegation.',
    '3': 'Fler besök än snitt till hälso- och sjukvårdsinstutioner (vårdcentral, sjukhus, tandläkare etc) vilket kräver ledsagning av personal ',
    '4': 'Ofta besök till hälso- och sjukvårdsinstutioner (vårdcentral, sjukhus, tandläkare etc) vilket kräver ledsagning av personal',
    '5': '',
  },
  Natt: {
    'Vet ej': '',
    '1': 'Inget stöd nattetid, sover utan tillsynsbehov',
    '2': 'Visst stöd vid enstaka tillfällen, kortare stunder. Sover mestadels utan behov av stöd.',
    '3': 'Behov av tillsyn men inte behov av vaken natt. Visst stöd vid flera tillfällen, kortare stunder.',
    '4': 'Behov av tillsyn och närvaro nattetid (vaken natt).',
    '5': '',
  },
  Psykomotorisk: {
    'Vet ej': '',
    '1': 'Kan hantera ångest själv. Kan behöva viss personalstöd vid ett fåtal och enstaka tillfällen',
    '2': 'Periodvist stöd vid oförutsedda förändringar, hanterar ångest med visst stöd.',
    '3': 'Lämnas inte utan tillsyn vid ångest. Ofta personalstöd',
    '4': 'Självdestruktivt beteende, kan vara skada för sig själv eller andra som kräver ständig närvaro. (1:1)',
    '5': 'Självdestruktivt beteende, kan vara skada för sig själv eller andra som kräver ständig närvaro. (2:1)',
  },
}
