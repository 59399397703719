import axios from 'axios'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { selectUser } from '../../store/userStore'

type Props = {}

const LoginRedirect: React.FC<Props> = () => {
  const userState = useSelector(selectUser)

  const { kommun } = useParams<{ kommun: string }>()

  const [loginProcessed, setLoginProcessed] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<boolean>(false)
  const location = window.location.search
  const queryParameters = new URLSearchParams(location)

  const hydra_url = process.env.REACT_APP_HYDRA_URL

  const getTenantId = (tenant: string) => {
    switch (tenant) {
      case 'sollentuna':
        return process.env.REACT_APP_CLIENT_ID_SOLLENTUNA
      case 'jonkoping':
        return process.env.REACT_APP_CLIENT_ID_JONKOPING
      case 'norrkoping':
        return process.env.REACT_APP_CLIENT_ID_NORRKOPING
      case 'hultsfred':
        return process.env.REACT_APP_CLIENT_ID_HULTSFRED
      case 'dev':
        return process.env.REACT_APP_CLIENT_ID_DEV
      case 'demo':
        return process.env.REACT_APP_CLIENT_ID_DEMO
      default:
        throw new Error('Error in kommun')
    }
  }

  const getTenantRedirectURI = (tenant: string) => {
    switch (tenant) {
      case 'sollentuna':
        return process.env.REACT_APP_REDIRECT_URI + '/sollentuna'
      case 'jonkoping':
        return process.env.REACT_APP_REDIRECT_URI + '/jonkoping'
      case 'norrkoping':
        return process.env.REACT_APP_REDIRECT_URI + '/norrkoping'
      case 'hultsfred':
        return process.env.REACT_APP_REDIRECT_URI + '/hultsfred'
      case 'dev':
        return process.env.REACT_APP_REDIRECT_URI + '/dev'
      case 'demo':
        return process.env.REACT_APP_REDIRECT_URI + '/demo'
      default:
        throw new Error('Error in kommun')
    }
  }

  const getToken = useCallback(async () => {
    try {
      if (queryParameters.get('error')) {
        setLoginError(true)
        return
      }

      const hydra_login_data = await axios.post(
        hydra_url + '/oauth2/token',
        qs.stringify({
          grant_type: 'authorization_code',
          code: queryParameters.get('code'),
          redirect_uri: getTenantRedirectURI(kommun),
          client_id: getTenantId(kommun),
        })
      )

      const hydra_at = hydra_login_data.data.access_token
      const hydra_refresh_token = hydra_login_data.data.refresh_token
      const hydra_idt = hydra_login_data.data.id_token
      const at_expires_at = Date.now() + hydra_login_data.data.expires_in

      localStorage.setItem('hydra_access_token', hydra_at)
      localStorage.setItem('hydra_refresh_token', hydra_refresh_token)
      localStorage.setItem('hydra_id_token', hydra_idt)
      localStorage.setItem('hydra_at_expires_at', at_expires_at)
      localStorage.setItem('tenant_id', getTenantId(kommun) || '')
      localStorage.setItem('tenant', kommun)

      setLoginProcessed(true)
    } catch (e) {
      console.error(e)
      setLoginError(true)
    }
  }, [])

  useEffect(() => {
    if (!loginProcessed) {
      getToken()
    }
  }, [userState])

  return (
    <>
      {loginError ? <Redirect to="/" /> : <></>}
      {loginProcessed ? <Redirect to="/app/start" /> : <></>}
    </>
  )
}

export default LoginRedirect
