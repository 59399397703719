import { FC } from 'react'
import { useForebyggandeInsatserTable } from './useForebyggandeInsatserTable'
import { Select, Tag } from 'antd'

type Props = Pick<ReturnType<typeof useForebyggandeInsatserTable>, 'options' | 'filteredInfo' | 'setFilteredInfo'>

export const ForebyggandeInsatserFilters: FC<Props> = ({
  setFilteredInfo,
  filteredInfo: {
    Id: idValue,
    Ålder: ålderValue,
    Kön: könValue,
    'Frånvaro på sjukhus': inläggningPåSjukhusValue,
    Fallolycka: fallolyckaValue,
    'Kognitiv svikt': kognitivSviktValue,
    Dagverksamhet: dagverksamhetValue,
    Korttidsboende: korttidsboendeValue,
    'Social aktivitet': socialAktivitetValue,
    'KVÅ - Balansträning': balansträningValue,
    'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': träningValue,
    'KVÅ - Kompressionsbehandling': kompressionsbehandlingValue,
    'KVÅ - Nutritionsbehandling': nutritionsbehandlingValue,
    'KVÅ - Omläggning av sår UNS': omläggningAvSårValue,
    'Hög larmfrekvens, flagga': högLarmfrekvensValue,
    'Avböjda besök, flagga': avböjdaBesökValue,
    'Trend omsorgsbehov 3mån': trendOmsorgsbehov3månValue,
    'Trend omsorgsbehov 6mån': trendOmsorgsbehov6månValue,
    'Utförd insats av fysioterapeut': utfördInsatsAvFysioterapeutValue,
    'Utförd insats av sjuksköterska': utfördInsatsAvSjuksköterskaValue,
    'Utförd insats av arbetsterapeut': utfördInsatsAvArbetsterapeutValue,
    'Utförd insats av omvårdnadspersonal': utfördInsatsAvOmvårdnadspersonalValue,
    'Risk för ökat omsorgsbehov': riskFörÖkatOmsorgsbehovValue,
    Resursbehov: resursbehovValue,
    'Nivå hemsjukvård': nivåHemsjukvårdValue,
    'Bedömt funktionstillstånd, Känsla av trygghet': bedömtFunktionstillståndKänslaAvTrygghetValue,
    'Avsett funktionstillstånd, Känsla av trygghet': avsettFunktionstillståndKänslaAvTrygghetValue,
    'Bedömt funktionstillstånd, Att förflytta sig själv': bedömtFunktionstillståndAttFörflyttaSigSjälvValue,
    'Avsett funktionstillstånd, Att förflytta sig själv': avsettFunktionstillståndAttFörflyttaSigSjälvValue,
    'Bedömt funktionstillstånd, Att genomföra daglig rutin': bedömtFunktionstillståndAttGenomföraDagligRutinValue,
    'Avsett funktionstillstånd, Att genomföra daglig rutin': avsettFunktionstillståndAttGenomföraDagligRutinValue,
  },
  options: {
    ids,
    ages,
    sex,
    inläggningPåSjukhus,
    fallolycka,
    kognitivSvikt,
    dagverksamhet,
    korttidsboende,
    socialAktivitet,
    balansträning,
    träning,
    kompressionsbehandling,
    nutritionsbehandling,
    omläggningAvSår,
    högLarmfrekvens,
    avböjdaBesök,
    trendOmsorgsbehov3mån,
    trendOmsorgsbehov6mån,
    utfördInsatsAvFysioterapeut,
    utfördInsatsAvSjuksköterska,
    utfördInsatsAvArbetsterapeut,
    utfördInsatsAvOmvårdnadspersonal,
    riskFörÖkatOmsorgsbehov,
    resursbehov,
    nivåHemsjukvård,
    bedömtFunktionstillståndKänslaAvTrygghet,
    avsettFunktionstillståndKänslaAvTrygghet,
    bedömtFunktionstillståndAttFörflyttaSigSjälv,
    avsettFunktionstillståndAttFörflyttaSigSjälv,
    bedömtFunktionstillståndAttGenomföraDagligRutin,
    avsettFunktionstillståndAttGenomföraDagligRutin,
  },
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      <Select
        style={{ width: '220px' }}
        placeholder={'Id'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(ids)
          .sort((a, b) => a.localeCompare(b))
          .map((age) => ({ label: age, value: age }))}
        value={idValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Id: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Id: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '220px' }}
        placeholder={'Ålder'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(ages)
          .sort((a, b) => a.localeCompare(b))
          .map((age) => ({ label: age, value: age }))}
        value={ålderValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Ålder: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Ålder: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '160px' }}
        placeholder={'Kön'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(sex)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => {
            return { label: s, value: s }
          })}
        value={könValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Kön: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Kön: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '300px' }}
        placeholder={'Risk för ökat omsorgsbehov'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(riskFörÖkatOmsorgsbehov)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={riskFörÖkatOmsorgsbehovValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Risk för ökat omsorgsbehov': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Risk för ökat omsorgsbehov: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '250px' }}
        placeholder={'Resursbehov'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(resursbehov)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: Number(s).toFixed(1), value: s }))}
        value={resursbehovValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Resursbehov: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Resursbehov: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '350px' }}
        placeholder={'Trend omsorgsbehov 3mån'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(trendOmsorgsbehov3mån)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={trendOmsorgsbehov3månValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Trend omsorgsbehov 3mån': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Trend omsorgsbehov 3mån: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '350px' }}
        placeholder={'Trend omsorgsbehov 6mån'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(trendOmsorgsbehov6mån)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={trendOmsorgsbehov6månValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Trend omsorgsbehov 6mån': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Trend omsorgsbehov 6mån: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '240px' }}
        placeholder={'Frånvaro på sjukhus'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(inläggningPåSjukhus)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={inläggningPåSjukhusValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Frånvaro på sjukhus': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Frånvaro på sjukhus: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '160px' }}
        placeholder={'Fallolycka'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(fallolycka)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={fallolyckaValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Fallolycka: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Fallolycka: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '190px' }}
        placeholder={'Kognitiv svikt'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(kognitivSvikt)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={kognitivSviktValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Kognitiv svikt': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Kognitiv svikt: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '200px' }}
        placeholder={'Dagverksamhet'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(dagverksamhet)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={dagverksamhetValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Dagverksamhet: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Dagverksamhet: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '200px' }}
        placeholder={'Korttidsboende'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(korttidsboende)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={korttidsboendeValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, Korttidsboende: value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Korttidsboende: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '200px' }}
        placeholder={'Social aktivitet'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(socialAktivitet)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={socialAktivitetValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Social aktivitet': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Social aktivitet: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '400px' }}
        placeholder={'KVÅ'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={[
          {
            value: 'Balansträning',
            label: 'KVÅ: Balansträning',
          },
          {
            value: 'Aktiv eller passiv träning av muskel- och ledrörlighet',
            label: 'KVÅ: Aktiv eller passiv träning av muskel- och ledrörlighet',
          },
          {
            value: 'Kompressionsbehandling',
            label: 'KVÅ: Kompressionsbehandling',
          },
          {
            value: 'Nutritionsbehandling',
            label: 'KVÅ: Nutritionsbehandling',
          },
          {
            value: 'Omläggning av sår UNS',
            label: 'KVÅ: Omläggning av sår UNS',
          },
        ]}
        value={[
          balansträningValue.includes('Ja') ? ['Balansträning'] : [],
          träningValue.includes('Ja') ? ['Aktiv eller passiv träning av muskel- och ledrörlighet'] : [],
          kompressionsbehandlingValue.includes('Ja') ? ['Kompressionsbehandling'] : [],
          nutritionsbehandlingValue.includes('Ja') ? ['Nutritionsbehandling'] : [],
          omläggningAvSårValue.includes('Ja') ? ['Omläggning av sår UNS'] : [],
        ].flat()}
        onChange={(value) =>
          setFilteredInfo((prev) => ({
            ...prev,
            'KVÅ - Balansträning': value.includes('Balansträning') ? ['Ja'] : [],
            'KVÅ - Aktiv eller passiv träning av muskel- och ledrörlighet': value.includes('Aktiv eller passiv träning av muskel- och ledrörlighet') ? ['Ja'] : [],
            'KVÅ - Kompressionsbehandling': value.includes('Kompressionsbehandling') ? ['Ja'] : [],
            'KVÅ - Nutritionsbehandling': value.includes('Nutritionsbehandling') ? ['Ja'] : [],
            'KVÅ - Omläggning av sår UNS': value.includes('Omläggning av sår UNS') ? ['Ja'] : [],
          }))
        }
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '260px' }}
        placeholder={'Hög larmfrekvens, flagga'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(högLarmfrekvens)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={högLarmfrekvensValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Hög larmfrekvens, flagga': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Hög larmfrekvens, flagga: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '250px' }}
        placeholder={'Avböjda besök, flagga'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(avböjdaBesök)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={avböjdaBesökValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Avböjda besök, flagga': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Avböjda besök, flagga: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '400px' }}
        placeholder={'Utförd insats'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={[
          {
            value: 'Utförd insats av fysioterapeut',
            label: 'Utförd insats av: fysioterapeut',
          },
          {
            value: 'Utförd insats av sjuksköterska',
            label: 'Utförd insats av: sjuksköterska',
          },
          {
            value: 'Utförd insats av arbetsterapeut',
            label: 'Utförd insats av: arbetsterapeut',
          },
          {
            value: 'Utförd insats av omvårdnadspersonal',
            label: 'Utförd insats av: omvårdnadspersonal',
          },
        ]}
        value={[
          utfördInsatsAvFysioterapeutValue.includes('Ja') ? ['Utförd insats av fysioterapeut'] : [],
          utfördInsatsAvSjuksköterskaValue.includes('Ja') ? ['Utförd insats av sjuksköterska'] : [],
          utfördInsatsAvArbetsterapeutValue.includes('Ja') ? ['Utförd insats av arbetsterapeut'] : [],
          utfördInsatsAvOmvårdnadspersonalValue.includes('Ja') ? ['Utförd insats av omvårdnadspersonal'] : [],
        ].flat()}
        onChange={(value) =>
          setFilteredInfo((prev) => ({
            ...prev,
            'Utförd insats av fysioterapeut': value.includes('Utförd insats av fysioterapeut') ? ['Ja'] : [],
            'Utförd insats av sjuksköterska': value.includes('Utförd insats av sjuksköterska') ? ['Ja'] : [],
            'Utförd insats av arbetsterapeut': value.includes('Utförd insats av arbetsterapeut') ? ['Ja'] : [],
            'Utförd insats av omvårdnadspersonal': value.includes('Utförd insats av omvårdnadspersonal') ? ['Ja'] : [],
          }))
        }
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '250px' }}
        placeholder={'Nivå hemsjukvård'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={Array.from(nivåHemsjukvård)
          .sort((a, b) => a.localeCompare(b))
          .map((s) => ({ label: s, value: s }))}
        value={nivåHemsjukvårdValue}
        onChange={(value) => setFilteredInfo((prev) => ({ ...prev, 'Nivå hemsjukvård': value }))}
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            Nivå hemsjukvård: {props.label}
          </Tag>
        )}
      />
      <Select
        style={{ width: '400px' }}
        placeholder={'IBIC-bedömning'}
        allowClear
        bordered
        mode="tags"
        showArrow
        options={[
          {
            label: 'Bedömt funktionstillstånd, Känsla av trygghet',
            options: Array.from(bedömtFunktionstillståndKänslaAvTrygghet)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `0${s}` })),
          },
          {
            label: 'Avsett funktionstillstånd, Känsla av trygghet',
            options: Array.from(avsettFunktionstillståndKänslaAvTrygghet)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `1${s}` })),
          },
          {
            label: 'Bedömt funktionstillstånd, Att förflytta sig själv',
            options: Array.from(bedömtFunktionstillståndAttFörflyttaSigSjälv)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `2${s}` })),
          },
          {
            label: 'Avsett funktionstillstånd, Att förflytta sig själv',
            options: Array.from(avsettFunktionstillståndAttFörflyttaSigSjälv)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `3${s}` })),
          },
          {
            label: 'Bedömt funktionstillstånd, Att genomföra daglig rutin',
            options: Array.from(bedömtFunktionstillståndAttGenomföraDagligRutin)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `4${s}` })),
          },
          {
            label: 'Avsett funktionstillstånd, Att genomföra daglig rutin',
            options: Array.from(avsettFunktionstillståndAttGenomföraDagligRutin)
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ label: s, value: `5${s}` })),
          },
        ]}
        value={[
          bedömtFunktionstillståndKänslaAvTrygghetValue.map((s) => `0${s}`),
          avsettFunktionstillståndKänslaAvTrygghetValue.map((s) => `1${s}`),
          bedömtFunktionstillståndAttFörflyttaSigSjälvValue.map((s) => `2${s}`),
          avsettFunktionstillståndAttFörflyttaSigSjälvValue.map((s) => `3${s}`),
          bedömtFunktionstillståndAttGenomföraDagligRutinValue.map((s) => `4${s}`),
          avsettFunktionstillståndAttGenomföraDagligRutinValue.map((s) => `5${s}`),
        ].flat()}
        onChange={(value) =>
          setFilteredInfo((prev) => ({
            ...prev,
            'Bedömt funktionstillstånd, Känsla av trygghet': value.filter((v) => v.startsWith('0')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
            'Avsett funktionstillstånd, Känsla av trygghet': value.filter((v) => v.startsWith('1')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
            'Bedömt funktionstillstånd, Att förflytta sig själv': value.filter((v) => v.startsWith('2')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
            'Avsett funktionstillstånd, Att förflytta sig själv': value.filter((v) => v.startsWith('3')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
            'Bedömt funktionstillstånd, Att genomföra daglig rutin': value.filter((v) => v.startsWith('4')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
            'Avsett funktionstillstånd, Att genomföra daglig rutin': value.filter((v) => v.startsWith('5')).map((v) => v.slice(1)) as Array<'1' | '2' | '3' | '4' | '5' | 'Ej specificerat'>,
          }))
        }
        maxTagCount={'responsive'}
        tagRender={(props) => (
          <Tag {...props} className="ant-select-selection-item" style={{ fontSize: '14px' }}>
            {(() => {
              switch (props.value[0]) {
                case '0':
                  return 'Bedömt funktionstillstånd, Känsla av trygghet'
                case '1':
                  return 'Avsett funktionstillstånd, Känsla av trygghet'
                case '2':
                  return 'Bedömt funktionstillstånd, Att förflytta sig själv'
                case '3':
                  return 'Avsett funktionstillstånd, Att förflytta sig själv'
                case '4':
                  return 'Bedömt funktionstillstånd, Att genomföra daglig rutin'
                case '5':
                  return 'Avsett funktionstillstånd, Att genomföra daglig rutin'
              }
            })()}
            : {props.label}
          </Tag>
        )}
      />
    </div>
  )
}
