import { Button, Select } from 'antd'
import React, { useMemo, useState } from 'react'
import { useGenerateLSSCsv } from '../../../api/beslutsstod/lss/useGenerateLSSCsv'
import FlagListHeader from '../../../components/header'
import DagligVerksamhet from './components/dagligVerksamhet/DagligVerksamhet'
import Korttid from './components/korttid/Korttid'

const { Option } = Select

type Props = {}

const BeslutsstodFunktionsstod: React.FC<Props> = (props) => {
  const { mutateAsync: _exportCsv } = useGenerateLSSCsv()

  const exportCsv = async () => {
    const csvData = await _exportCsv()
    const blob = new Blob([csvData as any], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${new Date()
      .toLocaleDateString('sv-se', { day: 'numeric', year: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' })
      .replace(' ', '-')
      .replace(':', '.')}-beslutsstöd-funktionsstöd.csv`
    a.click()
  }

  const [currentArea, setCurrentArea] = useState<
    'Boende med särskild service (LSS)' | 'Daglig verksamhet (LSS)' | 'Korttidstillsyn (LSS)' | 'Boendestöd' | 'Korttidsvistelse' | 'Särskild boende socialpsykiatri (SOL)'
  >('Boende med särskild service (LSS)')

  const currentAreaComponent = useMemo(() => {
    switch (currentArea) {
      case 'Boende med särskild service (LSS)': {
        // Only using korttid for now but keeping the BoendeMedSarskildService separate.
        // If we need to use it in the future as a separate view, we can uncomment the line below.
        // return <BoendeMedSarskildService />
        return <Korttid />
      }
      case 'Daglig verksamhet (LSS)': {
        return <DagligVerksamhet />
      }
      case 'Korttidstillsyn (LSS)': {
        return <Korttid />
      }
      case 'Boendestöd': {
        return <Korttid />
      }
      case 'Korttidsvistelse': {
        return <Korttid />
      }
      case 'Särskild boende socialpsykiatri (SOL)': {
        return <Korttid />
      }
    }
  }, [currentArea])

  return (
    <>
      <FlagListHeader title="" heading="Beslutsstöd Funktionsstöd" description={null} button={false} />

      <div>
        <div className="mb-8 flex justify-between">
          <Select placeholder="Välj verksamhetsområde och behovsmall" value={currentArea} onChange={(value) => setCurrentArea(value)} size="large" style={{ width: 350 }}>
            {['Boende med särskild service (LSS)', 'Daglig verksamhet (LSS)', 'Korttidstillsyn (LSS)', 'Boendestöd', 'Korttidsvistelse', 'Särskild boende socialpsykiatri (SOL)'].map((level) => (
              <Option key={`${level}`} value={level}>
                {level}
              </Option>
            ))}
          </Select>
          {['Boende med särskild service (LSS)', 'Korttidstillsyn (LSS)', 'Korttidsvistelse'].includes(currentArea) && (
            <Button type="primary" htmlType="button" className="w-[150px]" onClick={exportCsv}>
              Exportera till Excel
            </Button>
          )}
        </div>
        {currentAreaComponent}
      </div>
    </>
  )
}

export default BeslutsstodFunktionsstod
