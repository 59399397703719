import { Typography } from 'antd'
import FlagListHeader from '../../components/header'
import pejlenBarChart from './pejlen-bar-chart.png'
import pejlenStackedChart from './pejlen-stacked-chart.png'

const Start = () => {
  return (
    <div>
      <FlagListHeader
        heading="Start"
        title={''}
        description={`Välkommen till Pejlen!
Pejlen ska vara stöd för datadrivet förbättringsarbete.
Navigera genom menyraden till vänster för att komma in på de delar som du vill arbeta med.`}
        button={false}
      />
      {process.env.REACT_APP_CLIENT_ID_DEMO && localStorage.getItem('tenant_id') === process.env.REACT_APP_CLIENT_ID_DEMO && (
        <div className="mt-12 flex flex-col">
          <Typography.Title level={3}>Dashboard</Typography.Title>
          <Typography.Text>Dashboardens innehåll anpassas efter kommunens och användarens specifika önskemål och behov.</Typography.Text>
          <div className="mt-12 grid max-w-[1600px] grid-cols-2 gap-16">
            <img src={pejlenBarChart} alt="pejlen-bar-chart"></img>
            <img src={pejlenStackedChart} alt="pejlen-stacked-chart"></img>
          </div>
        </div>
      )}
    </div>
  )
}

export default Start
