import { Route, Switch } from 'react-router-dom'
import notFoundPage from './features/404'
import Hemtjanst from './features/Hemtjanst'
import Start from './features/Start'
import Anvandare from './features/anvandare'
import NewAnvandare from './features/anvandare/new'
import SingleAnvandare from './features/anvandare/single'
import Case from './features/arende'
import createCase from './features/arende/components/createCase/createCase'
import SingleCase from './features/arende/single'
import ArendeFordelning from './features/arendeFordelning'
import BenchmarkingMainView from './features/benchmarking'
import Beslutsstod from './features/beslutsstod'
import Brukare from './features/brukare'
import SingleBrukare from './features/brukare/single'
import Handlaggare from './features/handlaggare'
import SingleHandlaggare from './features/handlaggare/single'
import LoginRedirect from './features/loginRedirect/loginRedirect'
import LogoutRedirect from './features/logoutRedirect/logoutRedirect'
import Medarbetare from './features/medarbetare'
import SingleMedarbetare from './features/medarbetare/single'
import MinaArendenTable from './features/minaArenden'
import NyligenUtgångnaTable from './features/nyligenUtgångna'
import flagInfo from './features/singleDeviation/singleDeviation'
import UtanförTeamstrkturTable from './features/utanforTeamStruktur'
import Utforare from './features/utforare'
import SingleUtforare from './features/utforare/single'
import VolumeTable from './features/volymuppfoljning'
import BenchmarkingIntroduction from './features/benchmarking/BenchmarkingInformation'
import Tags from './features/tags'
import SingleTag from './features/tags/single'
import NewTag from './features/tags/new'
import Guests from './features/guests'
import NewGuest from './features/guests/NewGuest'
import SingleGuest from './features/guests/SingleGuest'
import MiscSettings from './features/miscSettings'
import Roles from './features/roles'
import NewRole from './features/roles/NewRole'
import SingleRole from './features/roles/SingleRole'
import IBICBeslutsstodEditView from './features/beslutsstod/IBIC/config/IBICBeslutsstodEditView'
import IBICBeslutsstod from './features/beslutsstod/IBIC/IBICBeslutsstod'
import BeslutsstodFunktionsstod from './features/beslutsstod/beslutsstod-funktionsstod/BeslutsstodFunktionsstod'
import ForebyggandeInsatserTable from './features/forebyggandeInsatser/forebyggandeInsatserTable'

const navigation = () => {
  return (
    <Switch>
      <Route exact path="/app/medarbetare" component={Medarbetare} />
      <Route exact path="/app/medarbetare/:id" component={SingleMedarbetare} />
      <Route exact path="/app/brukare" component={Brukare} />
      <Route exact path="/app/brukare/id=:id&namn=:namn" component={SingleBrukare} />
      <Route exact path="/app/handlaggare" component={Handlaggare} />
      <Route exact path="/app/handlaggare/:id" component={SingleHandlaggare} />
      <Route exact path="/app/utforare" component={Utforare} />
      <Route exact path="/app/utforare/utforare=:utforare&from=:from&to=:to&flaglevel=:flaglevel&deviation=:deviation" component={SingleUtforare} />
      <Route exact path="/app/forebyggande-insatser" component={ForebyggandeInsatserTable} />
      <Route exact path="/app/arenden" component={Case} />
      <Route exact path="/app/arenden/:id" component={SingleCase} />
      <Route exact path="/app/nytt-arende" component={createCase} />
      <Route exact path="/app/avvikelse/flag=:flag&id=:id" component={flagInfo} />
      <Route exact path="/app/anvandare" component={Anvandare} />
      <Route exact path="/app/anvandare/ny" component={NewAnvandare} />
      <Route exact path="/app/anvandare/:id" component={SingleAnvandare} />
      <Route exact path="/app/taggar" component={Tags} />
      <Route exact path="/app/taggar/ny" component={NewTag} />
      <Route exact path="/app/taggar/:id" component={SingleTag} />
      <Route exact path="/app/gäster" component={Guests} />
      <Route exact path="/app/gäster/ny" component={NewGuest} />
      <Route exact path="/app/gäster/:username" component={SingleGuest} />
      <Route exact path="/app/roller" component={Roles} />
      <Route exact path="/app/roller/ny" component={NewRole} />
      <Route exact path="/app/roller/:name" component={SingleRole} />
      <Route exact path="/app/övrigt" component={MiscSettings} />
      <Route exact path="/app/beslutsstod" component={Beslutsstod} />
      <Route exact path="/app/ibic-beslutsstod" component={IBICBeslutsstod} />
      <Route exact path="/app/ibic-beslutsstod/config" component={IBICBeslutsstodEditView} />
      <Route exact path="/app/beslutsstod-lss" component={BeslutsstodFunktionsstod} />
      <Route exact path="/app/beslutsstod/:id" component={Beslutsstod} />
      <Route exact path="/app/arendefordelning" component={ArendeFordelning} />
      <Route exact path="/app/hemtjanst" component={Hemtjanst} />
      <Route exact path="/app/benchmarking/introduction" component={BenchmarkingIntroduction} />
      <Route path="/app/benchmarking" component={BenchmarkingMainView} />
      <Route exact path="/app/callback" component={LoginRedirect} />
      <Route exact path="/app/logout_redirect" component={LogoutRedirect} />
      <Route exact path="/app/start" component={Start} />
      <Route exact path="/app/mina-arenden" component={MinaArendenTable} />
      <Route exact path="/app/nyligen-utganga" component={NyligenUtgångnaTable} />
      <Route exact path="/app/utanfor-teamstruktur" component={UtanförTeamstrkturTable} />
      <Route exact path="/app/volymuppfoljning" component={VolumeTable} />
      <Route path="*" component={notFoundPage} />
    </Switch>
  )
}

export default navigation
