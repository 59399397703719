import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../useApi'

interface SavePersonLSSPayload {
  personNumber: string
  processId: number
  note: string | undefined
  inputState: {
    forflyttning: string
    kommunikation: string
    social_interaktion: string
    personlig_vard: string
    hemliv: string
    fritid: string
    daglig_verksamhet: string
    psykomotorisk: string
    natt: string
    hsl_ledsagning: string
    justering_timmar?: number
    antal_vardagsdygn_per_manad?: number
    antal_helgdygn_per_manad?: number
  }
  resultState: {
    timmar_fran_grundschema_vardag: number
    timmar_fran_grundschema_helgdag: number
    timmar_fran_grundschema_vecka: number
    timmar_fran_grundschema_manad: number
    timmar_fran_grundschema_snitt_dygn: number
    vardag_dag_vardag: number
    vardag_dag_helgdag: number
    vardag_dag_vecka: number
    vardag_dag_manad: number
    vardag_dag_snitt_dygn: number
    vardag_kvall_vardag: number
    vardag_kvall_helgdag: number
    vardag_kvall_vecka: number
    vardag_kvall_manad: number
    vardag_kvall_snitt_dygn: number
    helg_dag_vardag: number
    helg_dag_helgdag: number
    helg_dag_vecka: number
    helg_dag_manad: number
    helg_dag_snitt_dygn: number
    helg_kvall_vardag: number
    helg_kvall_helgdag: number
    helg_kvall_vecka: number
    helg_kvall_manad: number
    helg_kvall_snitt_dygn: number
    natt_vardag: number
    natt_helgdag: number
    natt_vecka: number
    natt_manad: number
    natt_snitt_dygn: number
    totala_timmar_fran_bedomning_vardag: number
    totala_timmar_fran_bedomning_helgdag: number
    totala_timmar_fran_bedomning_vecka: number
    totala_timmar_fran_bedomning_manad: number
    totala_timmar_fran_bedomning_snitt_dygn: number
    extratimmar_fran_justering_vardag: number
    extratimmar_fran_justering_helgdag: number
    extratimmar_fran_justering_vecka: number
    extratimmar_fran_justering_manad: number
    extratimmar_fran_justering_snitt_dygn: number
    totalt_antal_timmar_vardag: number
    totalt_antal_timmar_helgdag: number
    totalt_antal_timmar_vecka: number
    totalt_antal_timmar_manad: number
    totalt_antal_timmar_snitt_dygn: number
    antal_arsarbetare: number
    ersattning_per_dygn: number
  }
}

export const useSavePersonLSS = () => {
  return useMutation({
    mutationFn: async ({ personNumber, processId, inputState, resultState, note }: SavePersonLSSPayload) => {
      const res = await apiClient.savePersonLSS(localStorage.getItem('tenant_id') as string, {
        personNumber,
        processId,
        note,
        inputState,
        resultState,
      })
      return res.data
    },
  })
}
