// Antd
import { Button, Divider, Form, Input, notification, Typography } from 'antd'

import { FC, Fragment, useMemo, useState } from 'react'
import { useSavePersonLSS } from '../../../../../api/beslutsstod/lss/useSavePersonLSS'
import { validatePersonnummer } from '../../../utils/validatePersonnummer'
import { KorttidFormValues, KorttidCalculationTimePeriod, KorttidCalculationType } from './types'
import { calculateTableData } from './utility/calculateTableData'

type Props = {
  data: KorttidFormValues
}

const KorttidCalculation: FC<Props> = ({ data }) => {
  const { mutateAsync: savePersonLSS } = useSavePersonLSS()

  const calculatedValues = useMemo(() => calculateTableData(data), [data])

  const [pnr, setPnr] = useState('')
  const [pid, setPid] = useState('')
  const [note, setNote] = useState('')

  const [notificationInstance, contextHolder] = notification.useNotification()

  const onPnrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPnr(event.target.value)
  }
  const onPidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPid(event.target.value)
  }
  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value)
  }

  const onSave = async () => {
    try {
      if (!pid || !pnr) {
        notificationInstance.error({
          message: 'Vänligen skriv in ett personnummer och ett process id och försök igen',
        })
        return null
      }
      const validatedPersonnummer = validatePersonnummer(pnr)
      if (validatedPersonnummer === null) {
        notificationInstance.error({
          message: 'Personnumret är inte giltigt',
        })
        return null
      }
      await savePersonLSS({
        note,
        personNumber: pnr,
        processId: Number(pid),
        inputState: {
          daglig_verksamhet: data['Daglig verksamhet'],
          psykomotorisk: data.Psykomotorisk,
          kommunikation: data.Kommunikation,
          social_interaktion: data['Social Interaktion'],
          personlig_vard: data['Personlig vård'],
          hemliv: data.Hemliv,
          fritid: data.Fritid,
          forflyttning: data.Förflyttning,
          natt: data.Natt,
          hsl_ledsagning: data['HSL Ledsagning'],
          justering_timmar: data.extraHours,
          antal_vardagsdygn_per_manad: data.vardagsDygnPerMonth,
          antal_helgdygn_per_manad: data.helgdagsDygnPerMonth,
        },
        resultState: {
          timmar_fran_grundschema_vardag: calculatedValues['Timmar från grundschema'].Vardag,
          timmar_fran_grundschema_helgdag: calculatedValues['Timmar från grundschema'].Helgdag,
          timmar_fran_grundschema_vecka: calculatedValues['Timmar från grundschema'].Vecka,
          timmar_fran_grundschema_manad: calculatedValues['Timmar från grundschema'].Månad,
          timmar_fran_grundschema_snitt_dygn: calculatedValues['Timmar från grundschema']['Snitt/dygn'],
          vardag_dag_vardag: calculatedValues['Vardag dag'].Vardag,
          vardag_dag_helgdag: calculatedValues['Vardag dag'].Helgdag,
          vardag_dag_vecka: calculatedValues['Vardag dag'].Vecka,
          vardag_dag_manad: calculatedValues['Vardag dag'].Månad,
          vardag_dag_snitt_dygn: calculatedValues['Vardag dag']['Snitt/dygn'],
          vardag_kvall_vardag: calculatedValues['Vardag kväll'].Vardag,
          vardag_kvall_helgdag: calculatedValues['Vardag kväll'].Helgdag,
          vardag_kvall_vecka: calculatedValues['Vardag kväll'].Vecka,
          vardag_kvall_manad: calculatedValues['Vardag kväll'].Månad,
          vardag_kvall_snitt_dygn: calculatedValues['Vardag kväll']['Snitt/dygn'],
          helg_dag_vardag: calculatedValues['Helg dag'].Vardag,
          helg_dag_helgdag: calculatedValues['Helg dag'].Helgdag,
          helg_dag_vecka: calculatedValues['Helg dag'].Vecka,
          helg_dag_manad: calculatedValues['Helg dag'].Månad,
          helg_dag_snitt_dygn: calculatedValues['Helg dag']['Snitt/dygn'],
          helg_kvall_vardag: calculatedValues['Helg kväll'].Vardag,
          helg_kvall_helgdag: calculatedValues['Helg kväll'].Helgdag,
          helg_kvall_vecka: calculatedValues['Helg kväll'].Vecka,
          helg_kvall_manad: calculatedValues['Helg kväll'].Månad,
          helg_kvall_snitt_dygn: calculatedValues['Helg kväll']['Snitt/dygn'],
          natt_vardag: calculatedValues.Natt.Vardag,
          natt_helgdag: calculatedValues.Natt.Helgdag,
          natt_vecka: calculatedValues.Natt.Vecka,
          natt_manad: calculatedValues.Natt.Månad,
          natt_snitt_dygn: calculatedValues.Natt['Snitt/dygn'],
          totala_timmar_fran_bedomning_vardag: calculatedValues['Extratimmar från bedömning'].Vardag,
          totala_timmar_fran_bedomning_helgdag: calculatedValues['Extratimmar från bedömning'].Helgdag,
          totala_timmar_fran_bedomning_vecka: calculatedValues['Extratimmar från bedömning'].Vecka,
          totala_timmar_fran_bedomning_manad: calculatedValues['Extratimmar från bedömning'].Månad,
          totala_timmar_fran_bedomning_snitt_dygn: calculatedValues['Extratimmar från bedömning']['Snitt/dygn'],
          extratimmar_fran_justering_vardag: calculatedValues['Extratimmar från justering'].Vardag,
          extratimmar_fran_justering_helgdag: calculatedValues['Extratimmar från justering'].Helgdag,
          extratimmar_fran_justering_vecka: calculatedValues['Extratimmar från justering'].Vecka,
          extratimmar_fran_justering_manad: calculatedValues['Extratimmar från justering'].Månad,
          extratimmar_fran_justering_snitt_dygn: calculatedValues['Extratimmar från justering']['Snitt/dygn'],
          totalt_antal_timmar_vardag: calculatedValues['Totalt antal timmar'].Vardag,
          totalt_antal_timmar_helgdag: calculatedValues['Totalt antal timmar'].Helgdag,
          totalt_antal_timmar_vecka: calculatedValues['Totalt antal timmar'].Vecka,
          totalt_antal_timmar_manad: calculatedValues['Totalt antal timmar'].Månad,
          totalt_antal_timmar_snitt_dygn: calculatedValues['Totalt antal timmar']['Snitt/dygn'],
          antal_arsarbetare: calculatedValues.numberOfYearlyWorkers,
          ersattning_per_dygn: calculatedValues.compensationPerDay,
        },
      })
      notificationInstance.success({
        message: 'Beräkningen har sparats',
      })
    } catch (error) {
      notificationInstance.error({
        message: 'Beräkningen kunde inte sparas',
      })
    }
  }

  return (
    <>
      {contextHolder}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1fr,1fr] items-center gap-2">
            <div />
            {Object.values(KorttidCalculationTimePeriod).map((item, index) => (
              <div key={index} className="">
                <Typography.Title level={5}>{item}</Typography.Title>
              </div>
            ))}

            <Divider className="col-span-full  w-full" style={{ marginBlock: 2 }} />
            {KorttidCalculationType.map((item, index) => (
              <Fragment key={`${item}-${index}`}>
                <div className={['Vardag dag', 'Vardag kväll', 'Helg dag', 'Helg kväll', 'Natt'].includes(item) ? 'pl-10 text-xs' : 'font-bold'}>
                  <Typography.Text>{item}</Typography.Text>
                </div>
                <div>{calculatedValues[item].Vardag.toFixed(1)}</div>
                <div>{calculatedValues[item].Helgdag.toFixed(1)}</div>
                <div>{calculatedValues[item].Vecka.toFixed(1)}</div>
                <div>{calculatedValues[item].Månad.toFixed(1)}</div>
                <div>{calculatedValues[item]['Snitt/dygn'].toFixed(1)}</div>
                <Divider className="col-span-full  w-full" style={{ marginBlock: 1 }} />
              </Fragment>
            ))}
          </div>
          <div className="grid min-w-[25%] max-w-[300px] grid-cols-[2fr,1fr] items-center gap-2">
            <Typography.Text>Antal årsarbetare</Typography.Text>
            <Typography.Text>{calculatedValues.numberOfYearlyWorkers.toFixed(1)}</Typography.Text>
            <Divider className="col-span-full  w-full" style={{ marginBlock: 0 }} />
            <Typography.Text>Dygnsersättning</Typography.Text>
            <Typography.Text>{calculatedValues.compensationPerDay.toFixed(0)}</Typography.Text>
            <Divider className="col-span-full  w-full" style={{ marginBlock: 0 }} />
          </div>
        </div>

        <div className="flex max-w-[50%] flex-col gap-4">
          <div className="flex gap-4">
            <Form.Item name={['Personnummer']} className="flex-grow">
              <Input onChange={onPnrChange} placeholder="Personnummer" />
            </Form.Item>
            <Form.Item name={['Process id']} className="flex-grow">
              <Input type="number" onChange={onPidChange} placeholder="Process id" />
            </Form.Item>
          </div>
          <Form.Item name={['Note']}>
            <Input.TextArea onChange={onNoteChange} placeholder="Kommentar" rows={4} style={{ resize: 'none' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="button" className="w-[150px]" onClick={onSave}>
              Spara beräkning
            </Button>
          </Form.Item>
        </div>
      </div>
    </>
  )
}

export default KorttidCalculation
