import { ActivityTimeframes, DagligVerksamhetInputTypes, DagligVerksamhetLevels, DagligVerksamhetLevelsExtended } from '../types'

const förflyttning: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 2,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 211,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 1,
    'Helg dagtid': 4,
    'Helg kväll': 1,
    Natt: 0,
    'Extrakostnader per dygn': 791,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const kommunikation: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 1,
    'Helg dagtid': 2,
    'Helg kväll': 1,
    Natt: 0,
    'Extrakostnader per dygn': 580,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 1,
    'Helg dagtid': 4,
    'Helg kväll': 1,
    Natt: 0,
    'Extrakostnader per dygn': 791,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const socialInteraktion: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 1,
    'Helg dagtid': 2,
    'Helg kväll': 1,
    Natt: 0,
    'Extrakostnader per dygn': 580,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 7,
    'Helg dagtid': 8,
    'Helg kväll': 7,
    Natt: 0,
    'Extrakostnader per dygn': 3427,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const personligVård: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0.25,
    'Vardag kväll': 0,
    'Helg dagtid': 0.25,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 92,
  },
  '4': {
    'Vardag dagtid': 0.25,
    'Vardag kväll': 0.25,
    'Helg dagtid': 0.25,
    'Helg kväll': 0.25,
    Natt: 0,
    'Extrakostnader per dygn': 185,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const hemliv: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 1,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 105,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0.5,
    'Helg dagtid': 1,
    'Helg kväll': 0.5,
    Natt: 0,
    'Extrakostnader per dygn': 290,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const fritid: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0.5,
    'Helg dagtid': 1,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 237,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 1,
    'Helg dagtid': 1,
    'Helg kväll': 1,
    Natt: 0,
    'Extrakostnader per dygn': 475,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const hslLedsagning: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0.5,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 132,
  },
  '4': {
    'Vardag dagtid': 1,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 264,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const natt: Record<keyof typeof DagligVerksamhetLevels, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0.5,
    'Extrakostnader per dygn': 250,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 2,
    'Extrakostnader per dygn': 1000,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 9,
    'Extrakostnader per dygn': 3198,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

const psykoMotorisk: Record<keyof typeof DagligVerksamhetLevelsExtended, Record<keyof typeof ActivityTimeframes, number>> = {
  '1': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '2': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
  '3': {
    'Vardag dagtid': 0,
    'Vardag kväll': 2,
    'Helg dagtid': 2,
    'Helg kväll': 2,
    Natt: 0,
    'Extrakostnader per dygn': 949,
  },
  '4': {
    'Vardag dagtid': 0,
    'Vardag kväll': 7,
    'Helg dagtid': 8,
    'Helg kväll': 7,
    Natt: 0,
    'Extrakostnader per dygn': 3427,
  },
  '5': {
    'Vardag dagtid': 0,
    'Vardag kväll': 14,
    'Helg dagtid': 18,
    'Helg kväll': 14,
    Natt: 0,
    'Extrakostnader per dygn': 7065,
  },
  'Vet ej': {
    'Vardag dagtid': 0,
    'Vardag kväll': 0,
    'Helg dagtid': 0,
    'Helg kväll': 0,
    Natt: 0,
    'Extrakostnader per dygn': 0,
  },
}

export const DagligVerksamhetTypesLevelMapping = {
  [DagligVerksamhetInputTypes.Förflyttning]: förflyttning,
  [DagligVerksamhetInputTypes.Kommunikation]: kommunikation,
  [DagligVerksamhetInputTypes['Social Interaktion']]: socialInteraktion,
  [DagligVerksamhetInputTypes['Personlig vård']]: personligVård,
  [DagligVerksamhetInputTypes.Hemliv]: hemliv,
  [DagligVerksamhetInputTypes.Fritid]: fritid,
  [DagligVerksamhetInputTypes.Psykomotorisk]: psykoMotorisk,
  [DagligVerksamhetInputTypes.Natt]: natt,
  [DagligVerksamhetInputTypes['HSL Ledsagning']]: hslLedsagning,
}

export const basePrice = 3796
export const hoursBaseSchedule = 271
export const averageHoursPerWeekWeekday = 39.45
export const averageHoursPerWeekWeekend = 37
export const staffCostPerHour = 369
