import { Divider, FormProps } from 'antd'
import React, { useState } from 'react'
import DagligVerksamhetCalculation from './DagligVerksamhetCalculation'
import DagligVerksamhetInput from './DagligVerksamhetInput'
import { DagligVerksamhetFormValues } from './types'

type Props = {}

const DagligVerksamhet: React.FC<Props> = (props) => {
  const [submitData, setSubmitData] = useState<DagligVerksamhetFormValues | null>(null)

  const onFinish = (values: DagligVerksamhetFormValues) => {
    setSubmitData(values)
  }
  const onFinishFailed: FormProps<DagligVerksamhetFormValues>['onFinishFailed'] = (error) => {
    console.error(error)
    setSubmitData(null)
  }

  return (
    <div className="grid grid-cols-[40%,96px,auto] gap-4">
      <DagligVerksamhetInput onFinish={onFinish} onFinishFailed={onFinishFailed} />
      <Divider type="vertical" style={{ height: '100%', margin: 'auto', borderLeft: '1px solid black' }} />

      {submitData && <DagligVerksamhetCalculation data={submitData} />}
    </div>
  )
}

export default DagligVerksamhet
